import { useMemo } from 'react'
import { useTheme } from '@/Hooks'

const useCategory = () => {
  const { Images } = useTheme()
  // 酒種類資料
  const data = useMemo(() => {
    return [
      {
        id: '1',
        keys: 'whisky',
        filters: 'category',
        title: 'Whisky',
        zhTitle: '威士忌',
        icon: Images.home_whisky_icon,
        block: Images.home_whisky_block,
      },
      {
        id: '2',
        keys: 'brandy,cognac',
        filters: 'category',
        title: 'Brandy / Cognac',
        zhTitle: '白蘭地 / 干邑',
        icon: Images.home_brandy_icon,
        block: Images.home_brandy_block,
      },
      {
        id: '3',
        keys: 'gin',
        filters: 'category',
        title: 'Gin',
        zhTitle: '琴酒',
        icon: Images.home_gin_icon,
        block: Images.home_gin_block,
      },
      {
        id: '4',
        keys: 'rum',
        filters: 'category',
        title: 'Rum',
        zhTitle: '蘭姆酒',
        icon: Images.home_rum_icon,
        block: Images.home_rum_block,
      },
      {
        id: '5',
        keys: 'vodka',
        filters: 'category',
        title: 'Vodka',
        zhTitle: '伏特加',
        icon: Images.home_vodka_icon,
        block: Images.home_vodka_block,
      },
      {
        id: '6',
        keys: 'tequila',
        filters: 'category',
        title: 'Tequila',
        zhTitle: '龍舌蘭',
        icon: Images.home_tequila_icon,
        block: Images.home_tequila_block,
      },
      // {
      //   id: '7',
      //   keys: 'absinthe',
      //   filters: 'category',
      //   title: 'Absinthe',
      //   zhTitle: '艾比森酒',
      //   icon: Images.home_absinthe_icon,
      //   block: Images.home_absinthe_block,
      // },
      // {
      //   id: '8',
      //   keys: 'vermouth',
      //   filters: 'category',
      //   title: 'Vermouth',
      //   zhTitle: '苦艾酒',
      //   icon: Images.home_vermouth_icon,
      //   block: Images.home_vermouth_block,
      // },
      {
        id: '9',
        keys: 'whisky,brandy,gin,rum,vodka,tequila',
        filters: 'categoryNot',
        title: 'Others',
        zhTitle: '其他種類',
        block: Images.home_others_block,
      },
    ]
  }, [Images])

  return data
}

export default useCategory
