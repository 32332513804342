import React, { useCallback, useEffect } from 'react'
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  Image,
  ScrollView,
  ActivityIndicator,
  Dimensions,
} from 'react-native'
import { useFullScreen, useTheme } from '@/Hooks'
import { FadeInView, WarningSignComponent } from '@/Components'
import { Picker } from '@react-native-picker/picker'
import { BorderRadius, Colors } from '@/Theme/Variables'
import { userApi } from '@/Services/modules/users'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Appbar, Avatar } from 'react-native-paper'
import achievementsApi from '@/Services/modules/achievements'
import { TextInput } from 'react-native-paper'
import { dataUriToFile, removeEmptyKeys } from '@/Util/global'
import { globalActions } from '@/Store/Global'
import { launchImageLibrary } from 'react-native-image-picker'
import ReactCrop, { type Crop } from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { manipulateAsync } from 'expo-image-manipulator'
import SelectDropdown from 'react-native-select-dropdown'
import spiritsApi from '@/Services/modules/spirits'
import { DownLoadSign } from '@/Components'
import { PagesEnum } from '@/Navigators/Application'

const EditProfileContainer = ({ navigation, route }: any) => {
  const { Images, Layout, Gutters, Fonts } = useTheme()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isFullScreen = useFullScreen()
  const styles = stylesSheet()

  const {
    data: getMeData,
    isLoading: getMeIsLoading,
    isSuccess: getMeDataIsSuccess,
  } = userApi.useGetMeQuery()

  const {
    data: getMyAchievementsData,
    isSuccess: getMyAchievementsIsSuccess,
    isLoading: getMyAchievementsIsLoading,
  } = achievementsApi.useGetMyAchievementsQuery()

  const [
    patchMeRequest,
    { isSuccess: patchMeIsSuccess, isLoading: patchMeIsLoading },
  ] = userApi.usePatchUserDataMutation()

  // 上傳圖檔至 image server
  const [postMediaRequest, { isLoading: postMediaIsLoading }] =
    spiritsApi.usePostMediaMutation()

  const [step, setStep] = React.useState<'editProfile' | 'editImage'>(
    'editProfile',
  )
  const [nickName, setNickName] = React.useState('')
  const [title, setTitle] = React.useState('')
  const [titleIndex, setTitleIndex] = React.useState<number>()
  const [titleGroup, setTitleGroup] = React.useState<
    {
      slug: string
      title: string
    }[]
  >([])
  const [editAvatar, setEditAvatar] = React.useState('')
  const [avatar, setAvatar] = React.useState('')
  const [crop, setCrop] = React.useState<Crop>()
  const [cropObj, setCropObj] = React.useState({})

  useEffect(() => {
    if (getMeDataIsSuccess) {
      setNickName(getMeData?.name)
      setTitle(getMeData?.title?.name)
      setAvatar(getMeData?.avatar)
    }
  }, [getMeDataIsSuccess, getMeData])

  // 取得可以選取的稱號 asUserTitle = true && progress = 100
  useEffect(() => {
    if (getMyAchievementsIsSuccess && getMeDataIsSuccess) {
      const result = [{ slug: 'null', title: '不顯示' }]
      for (const item of getMyAchievementsData?.data) {
        for (const subItem of item?.data) {
          if (subItem?.asUserTitle && subItem?.progress === 100) {
            result.push({
              slug: subItem?.slug,
              title: subItem?.name,
            })
          }
        }
      }
      const index =
        result.findIndex(item => item.title === getMeData?.title?.name) || 0

      // 如果都沒有稱號可以選擇，不顯示則改成尚未取得可顯示稱號
      if (result.length === 1) {
        result[0].title = '尚未取得可顯示稱號'
      }

      setTitleIndex(index)
      setTitleGroup(result)
    }
  }, [
    getMyAchievementsIsSuccess,
    getMyAchievementsData,
    getMeData,
    getMeDataIsSuccess,
  ])

  useEffect(() => {
    if (patchMeIsSuccess) {
      dispatch(
        globalActions.openSnackbar({
          visible: true,
          message: '儲存成功',
          type: 'success',
          showIcon: false,
        }),
      )
      navigation.navigate('ProfileMainContainer', {
        id: getMeData?.id,
      })
    }
  }, [dispatch, getMeData?.id, navigation, patchMeIsSuccess])

  const handleGoBack = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack()
      return
    }

    navigation.navigate(PagesEnum.Main, {
      screen: 'Profile',
    })
  }, [navigation])

  const handleSaveProfile = async () => {
    let avatarImagePath = ''
    const titleSlug = titleGroup?.find(item => item.slug === title)?.slug

    // 如果有更換大頭貼
    if (editAvatar) {
      const avatarFile = dataUriToFile(editAvatar, nickName)
      const fileSizeInMB = avatarFile?.size / 1024 / 1024
      if (fileSizeInMB >= 5) {
        dispatch(
          globalActions.openSnackbar({
            visible: true,
            message: '檔案大小超過 5MB，請重新選擇照片',
            type: 'default',
          }),
        )
        return
      }
      const mediaResponse = await postMediaRequest({
        media: avatarFile,
      }).unwrap()
      avatarImagePath = mediaResponse?.data?.path
    }

    const payload = removeEmptyKeys({
      name: nickName,
      titleSlug: titleSlug,
      avatar: avatarImagePath,
    })

    patchMeRequest({
      ...payload,
      titleSlug: titleSlug === 'null' ? null : titleSlug,
    })
  }

  //   開啟相簿
  const handleOpenGallery = async () => {
    const result = await launchImageLibrary({
      mediaType: 'photo',
      selectionLimit: 1,
    })
    if (result?.assets) {
      const dataURI = result?.assets[0]?.uri
      // setStep('editImage')
      // setEditAvatar(dataURI || '')
      handleApplyCrop(dataURI || '')
    }
  }

  const handleCropComplete = async (crop: any) => {
    const object = {
      originX: crop.x,
      originY: crop.y,
      width: crop.width,
      height: crop.height,
    }
    setCropObj(object)
  }

  // TODO: 裁切圓形
  const handleApplyCrop = async (imageUri: any) => {
    const image = await manipulateAsync(imageUri, [
      {
        resize: {
          width: 200,
          height: 200,
        },
      },
    ])

    setEditAvatar(image.uri)
    setStep('editProfile')
  }

  if (
    getMeIsLoading ||
    getMyAchievementsIsLoading ||
    patchMeIsLoading ||
    postMediaIsLoading
  ) {
    return (
      <View
        style={[
          Layout.fill,
          Layout.rowCenter,
          { backgroundColor: Colors.background.surface },
        ]}
      >
        <ActivityIndicator size="large" color={Colors.primary} />
      </View>
    )
  }

  const renderEditProfile = () => {
    return (
      <View>
        <Appbar.Header
          style={[
            Layout.fullWidth,
            Layout.row,
            Layout.justifyContentBetween,
            Gutters.regularHPadding,
            {
              backgroundColor: Colors.background.default,
            },
          ]}
        >
          <View
            style={[
              Layout.colCenter,
              Layout.iconSize32,
              Gutters.regularRPadding,
              {
                borderRadius: BorderRadius.radius16,
                backgroundColor: Colors.fontText.dark.primary3,
              },
            ]}
          >
            <TouchableOpacity
              onPress={handleGoBack}
              style={{
                paddingLeft: navigation.canGoBack() ? 32 : 24,
                paddingBottom: 2,
              }}
            >
              <Image
                style={[Layout.iconSize24]}
                source={
                  navigation.canGoBack() ? Images.arrowLeft : Images.close
                }
                resizeMode="contain"
              />
            </TouchableOpacity>
          </View>
          <View>
            <Text
              style={[
                Fonts.size16,
                Fonts.weight500,
                { color: Colors.fontText.light.primary2 },
              ]}
            >
              編輯個人資料
            </Text>
          </View>
          <TouchableOpacity
            onPress={handleSaveProfile}
            style={{ paddingLeft: 24, paddingBottom: 2 }}
          >
            <Text
              style={[Fonts.size16, Fonts.weight400, { color: Colors.primary }]}
            >
              儲存
            </Text>
          </TouchableOpacity>
        </Appbar.Header>
        <ScrollView style={[Layout.fill]}>
          {/* 個人資訊 */}
          <FadeInView
            duration={550}
            style={{ backgroundColor: Colors.background.default }}
          >
            <View style={[Gutters.smallVPadding, Gutters.smallHPadding]}>
              <View style={[Layout.row, Layout.iconSize56]}>
                <Avatar.Image
                  source={{
                    uri: editAvatar || avatar,
                  }}
                  style={[Gutters.smallBMargin, Layout.iconSize56]}
                  size={56}
                />
                <TouchableOpacity onPress={() => handleOpenGallery()}>
                  <Image
                    style={[
                      Layout.iconSize24,
                      { position: 'absolute', right: -5, bottom: 0 },
                    ]}
                    source={Images.profile_main_edit}
                    resizeMode="contain"
                  />
                </TouchableOpacity>
              </View>
            </View>
          </FadeInView>
          <View style={[Gutters.smallHPadding]}>
            <View style={[Gutters.tinyBMargin]}>
              <TextInput
                label="暱稱"
                value={nickName}
                autoFocus={true}
                style={[{ color: Colors.white }, styles.textInput]}
                underlineColor="transparent"
                activeUnderlineColor="transparent"
                activeOutlineColor={Colors.primary}
                theme={{
                  colors: {
                    onSurfaceVariant: Colors.fontText.light.primary3,
                    error: Colors.snackbar.error,
                    outline: Colors.background.top,
                  },
                  roundness: 10,
                }}
                textColor="#fff"
                mode="outlined"
                //   error={!!errorMessage?.account}
                onChangeText={value => setNickName(value)}
              />
            </View>
            <View style={[Gutters.smallBMargin]}>
              {!!titleGroup?.length && (
                <SelectDropdown
                  data={titleGroup}
                  defaultValueByIndex={titleIndex || 0}
                  defaultButtonText="顯示頭銜"
                  disabledIndexs={titleGroup?.length === 1 ? [0] : []}
                  onSelect={selectedItem => {
                    setTitle(selectedItem.slug)
                  }}
                  buttonTextAfterSelection={selectedItem => {
                    // text represented after item is selected
                    // if data array is an array of objects then return selectedItem.property to render after item is selected
                    return selectedItem.title
                  }}
                  rowTextForSelection={item => {
                    // text represented for each item in dropdown
                    // if data array is an array of objects then return item.property to represent item in dropdown
                    return item.title
                  }}
                  dropdownStyle={{
                    backgroundColor: Colors.background.onSurface,
                  }}
                  rowTextStyle={{
                    color: Colors.fontText.light.primary2,
                    textAlign: 'left',
                    paddingLeft: 10,
                    paddingVertical: 8,
                  }}
                  buttonStyle={{
                    width: '100%',
                    borderWidth: 2,
                    borderColor: 'white',
                    borderRadius: 10,
                    height: 56,
                    backgroundColor: Colors.background.default,
                  }}
                  buttonTextStyle={{
                    color: Colors.fontText.light.primary2,
                    textAlign: 'left',
                  }}
                  renderDropdownIcon={() => (
                    <Image
                      source={Images.arrowRight}
                      resizeMode="contain"
                      style={[
                        Layout.iconSize20,
                        Gutters.smallRPadding,
                        {
                          transform: [{ rotate: '90deg' }],
                        },
                      ]}
                    />
                  )}
                />
              )}
            </View>
            <View style={[Layout.row]}>
              <Image
                source={Images.profile_main_info}
                resizeMode="contain"
                style={[Layout.iconSize24, Gutters.tinyRMargin]}
              />
              <Text
                style={[
                  Fonts.size12,
                  Fonts.weight400,
                  { color: Colors.fontText.light.primary3 },
                ]}
              >
                僅獲取特定徽章才可顯示該稱號。請前往成就徽章頁面了解如何獲取。
              </Text>
            </View>
          </View>
        </ScrollView>
      </View>
    )
  }

  const renderEditImage = () => {
    return (
      <View>
        <Appbar.Header
          style={[
            Layout.fullWidth,
            Layout.row,
            Layout.justifyContentBetween,
            Gutters.regularHPadding,
            {
              backgroundColor: Colors.background.default,
            },
          ]}
        >
          <View
            style={[
              Layout.colCenter,
              Layout.iconSize32,
              Gutters.regularRPadding,
              {
                borderRadius: BorderRadius.radius16,
                backgroundColor: Colors.fontText.dark.primary3,
              },
            ]}
          >
            <TouchableOpacity
              onPress={() => {
                setStep('editProfile')
                setEditAvatar('')
              }}
              style={{
                paddingLeft: navigation.canGoBack() ? 32 : 24,
                paddingBottom: 2,
              }}
            >
              <Image
                style={[Layout.iconSize24]}
                source={Images.close}
                resizeMode="contain"
              />
            </TouchableOpacity>
          </View>
          <View>
            <Text
              style={[
                Fonts.size16,
                Fonts.weight500,
                { color: Colors.fontText.light.primary2 },
              ]}
            >
              變更頭像
            </Text>
          </View>
          <TouchableOpacity
            onPress={() => {
              handleApplyCrop()
            }}
            style={{ paddingLeft: 24, paddingBottom: 2 }}
          >
            <Text
              style={[Fonts.size16, Fonts.weight400, { color: Colors.primary }]}
            >
              套用
            </Text>
          </TouchableOpacity>
        </Appbar.Header>
        <ScrollView style={[Layout.fill]}>
          <View style={[Layout.fill]}>
            <ReactCrop
              crop={crop}
              minWidth={200}
              maxWidth={200}
              minHeight={200}
              maxHeight={200}
              circularCrop={true}
              onChange={c => setCrop(c)}
              onComplete={c => handleCropComplete(c)}
            >
              <Image
                source={{ uri: editAvatar }}
                resizeMode="contain"
                style={{
                  height: Dimensions.get('window').height,
                }}
              />
            </ReactCrop>
          </View>
        </ScrollView>
      </View>
    )
  }

  const renderContent = (nowStep: string) => {
    const render: Record<string, any> = {
      editProfile: renderEditProfile(),
      editImage: renderEditImage(),
    }
    return render[nowStep]
  }

  return (
    <View
      style={[
        Layout.fill,
        Layout.justifyContentBetween,
        isFullScreen && Gutters.largeTPadding,
        { backgroundColor: Colors.background.default },
      ]}
    >
      {renderContent(step)}
      <View>
        <WarningSignComponent bottomSpace />
      </View>
      <DownLoadSign />
    </View>
  )
}

const stylesSheet = () =>
  StyleSheet.create({
    textInput: {
      backgroundColor: Colors.background.default,
      height: 56,
    },
  })

export default EditProfileContainer
