import React from 'react'
import { View, ActivityIndicator, StyleSheet } from 'react-native'
import { useTheme } from '@/Hooks'
import { Colors } from '@/Theme/Variables'

const LoadingComponent = () => {
  const { Layout } = useTheme()

  return (
    <View style={[Layout.fullSize, Layout.colCenter, styles.container]}>
      <ActivityIndicator size="large" color={Colors.primary} />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'rgba(0,0,0,0.5)',
    position: 'absolute',
    top: 0,
    bottom: 0,
    zIndex: 999,
  },
})

export default LoadingComponent
