import React, { useCallback, useEffect, useState, useRef } from 'react'
import {
  View,
  TouchableOpacity,
  Image,
  Text,
  StyleSheet,
  ScrollView,
  Dimensions,
  TextInput,
} from 'react-native'
import { useFlavorData, useTheme } from '@/Hooks'
import { Colors } from '@/Theme/Variables'
import { FadeInView, Header } from '@/Components'
import { capitalizeFirstLetter } from '@/Util/global'
import { useTranslation } from 'react-i18next'
import { spiritActions } from '@/Store/Spirit'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '@/Store'
import { navigateAndSimpleReset } from '@/Navigators/utils'
import noteApi from '@/Services/modules/note'
import { NestedSetType } from '@/Services/modules/note/note.type'
import useDebounce from '@/Util/useDebounce'
import { DownLoadSign } from '@/Components'
import { PagesEnum } from '@/Navigators/Application'

const FlavorContainer = ({ navigation }: any) => {
  const { Layout, Gutters, Images, Fonts } = useTheme()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { flavors: selectedFlavorList } = useSelector(
    (state: RootState) => state.spirit.spirit,
  )
  const { result: FlavorData, handleGetFlavorColor } = useFlavorData()

  const flavorListRef = useRef<any>()
  const textInputRef = useRef<any>()

  // 取得風味資訊
  noteApi.useGetNestedSetQuery({
    type: NestedSetType.FLAVOR_WHEEL,
  })

  const [searchKeyword, setSearchKeyword] = useState<string>('')
  const [searchValues, setSearchValues] = useState<any[]>([])
  const [searchIsSuccess, setSearchIsSuccess] = useState<boolean>(false)

  const styles = getStyle(searchKeyword)

  const handleGoBack = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack()
      return
    }
    navigateAndSimpleReset(PagesEnum.Main)
  }, [navigation])

  // 自動 focus 搜尋欄位
  useEffect(() => {
    textInputRef?.current?.focus()
  }, [])

  const handleClearSearchKeyword = () => {
    setSearchKeyword('')
  }

  // 搜尋
  const fetchData = () => {
    setSearchIsSuccess(false)

    if (searchKeyword.length === 0) {
      setSearchValues([])
      setSearchIsSuccess(true)
      return
    }

    const value = []
    for (const flavorDataItem of FlavorData) {
      const flavorArr = []
      for (const item of flavorDataItem.flavor) {
        if (
          item?.key?.includes(searchKeyword) ||
          item?.name?.includes(searchKeyword)
        ) {
          flavorArr.push(item)
        }
      }

      if (flavorArr?.length) {
        value.push({
          ...flavorDataItem,
          flavor: flavorArr,
        })
      }
    }

    setSearchValues(value)
    setSearchIsSuccess(true)
  }

  // debounce 搜尋
  useDebounce(fetchData, 1000, [searchKeyword])

  const handleSaveFlavorDataInStore = (data: string[]) => {
    dispatch(
      spiritActions.setSpiritData({
        flavors: data,
      }),
    )
  }

  // 移除風味
  const handleRemoveSelectFlavorItem = (flavor: string) => {
    const newSelectedFlavorList = selectedFlavorList?.filter(
      item => item !== flavor,
    )
    handleSaveFlavorDataInStore(newSelectedFlavorList || [])
  }

  return (
    <View style={[Layout.fill, { backgroundColor: Colors.background.default }]}>
      <ScrollView
        style={(Layout.fill, { height: Dimensions.get('window').height })}
      >
        <FadeInView duration={500} style={Layout.fill}>
          <Header
            title="風味標記"
            styles={{
              alignItems: 'center',
              flexDirection: 'column',
            }}
          />
          <View
            style={[
              Gutters.smallHPadding,
              Gutters.smallBMargin,
              Layout.row,
              Layout.alignItemsCenter,
            ]}
          >
            <TouchableOpacity onPress={handleGoBack}>
              <Image
                style={[Layout.iconSize24]}
                source={Images.arrowLeft}
                resizeMode="contain"
              />
            </TouchableOpacity>
            <View
              style={[
                Layout.row,
                styles.searchInputContainer,
                Gutters.smallHPadding,
              ]}
            >
              <TextInput
                ref={textInputRef}
                style={[styles.searchInput, { color: Colors.white }]}
                value={searchKeyword}
                placeholder="搜尋風味"
                placeholderTextColor={Colors.gray}
                returnKeyType="search"
                returnKeyLabel="search"
                onChangeText={text => {
                  setSearchKeyword(text)
                  setSearchValues([])
                  setSearchIsSuccess(false)
                }}
                onSubmitEditing={fetchData}
              />
              {!!searchKeyword?.length && (
                <TouchableOpacity onPress={handleClearSearchKeyword}>
                  <Image
                    source={Images.close}
                    style={Layout.iconSize24}
                    resizeMode="contain"
                  />
                </TouchableOpacity>
              )}
            </View>
          </View>
          <View
            ref={flavorListRef}
            style={[
              Gutters.tinyBMargin,
              { backgroundColor: Colors.background.surface },
            ]}
          >
            {/* 已標注風味 */}
            <View style={[Gutters.smallHPadding, Gutters.smallVPadding]}>
              <Text
                style={[
                  Fonts.weight700,
                  Fonts.size16,
                  { color: Colors.secondaryText },
                ]}
              >
                已標注風味
              </Text>
            </View>
            {/* 被選取的風味列表 */}
            <View
              style={[
                Layout.row,
                Gutters.smallBPadding,
                Gutters.smallHPadding,
                styles.flavorSelectedItemContainer,
              ]}
            >
              {selectedFlavorList?.length ? (
                selectedFlavorList?.map(item => {
                  const color = handleGetFlavorColor(item)
                  return (
                    <View
                      style={[
                        Layout.row,
                        Layout.alignItemsCenter,
                        styles.flavorSelectedItem,
                      ]}
                    >
                      <View
                        style={[
                          Gutters.miniRMargin,
                          styles.flavorDot,
                          { backgroundColor: color },
                        ]}
                      />
                      <Text
                        style={[
                          Fonts.size14,
                          Fonts.weight700,
                          Gutters.miniRMargin,
                          {
                            color: Colors.fontText.light.primary2,
                          },
                        ]}
                      >
                        {t(`flavor-wheel:${item}`)}
                      </Text>
                      <TouchableOpacity
                        onPress={() => handleRemoveSelectFlavorItem(item)}
                      >
                        <Image
                          source={Images.close}
                          resizeMode="contain"
                          style={Layout.iconSize14}
                        />
                      </TouchableOpacity>
                    </View>
                  )
                })
              ) : (
                <Text
                  style={[
                    Fonts.weight400,
                    Fonts.size14,
                    { color: Colors.darkText },
                  ]}
                >
                  尚無紀錄
                </Text>
              )}
            </View>
          </View>
          <View style={{ backgroundColor: Colors.background.surface }}>
            {/* 搜尋結果 */}
            {!!searchKeyword?.length && searchValues?.length > 0 && (
              <View style={[Gutters.smallHPadding]}>
                <View style={[Gutters.smallVPadding]}>
                  <Text
                    style={[
                      Fonts.weight700,
                      Fonts.size16,
                      { color: Colors.secondaryText },
                    ]}
                  >
                    搜尋結果
                  </Text>
                </View>
                {/* 搜尋結果列表 */}
                <View>
                  {searchValues?.length &&
                    searchValues?.map(item => {
                      return (
                        <View>
                          <View
                            style={[
                              Layout.row,
                              Layout.alignItemsCenter,
                              Gutters.smallBMargin,
                            ]}
                          >
                            <Text
                              style={[
                                Fonts.size20,
                                Fonts.weight500,
                                { color: Colors.white },
                              ]}
                            >{`${capitalizeFirstLetter(item?.enName)}`}</Text>
                            <Text
                              style={[
                                Fonts.size14,
                                Fonts.weight400,
                                { color: Colors.white },
                              ]}
                            >{` ${item?.zhName}`}</Text>
                          </View>
                          {/* 風味列表 */}
                          <View
                            style={[
                              Layout.row,
                              Gutters.regularBMargin,
                              { flexWrap: 'wrap' },
                            ]}
                          >
                            {!!item?.flavor &&
                              item?.flavor?.map((flavor: any) => {
                                const color = handleGetFlavorColor(flavor.key)
                                const isSelected = selectedFlavorList?.includes(
                                  flavor.key,
                                )
                                return (
                                  <TouchableOpacity
                                    style={[
                                      Layout.row,
                                      Layout.alignItemsCenter,
                                      styles.flavorSelectedItem,
                                      {
                                        opacity: isSelected ? 0.5 : 1,
                                      },
                                    ]}
                                    disabled={isSelected}
                                    onPress={() => {
                                      handleSaveFlavorDataInStore([
                                        ...(selectedFlavorList || []),
                                        flavor.key,
                                      ])
                                    }}
                                  >
                                    <View
                                      style={[
                                        Gutters.miniRMargin,
                                        styles.flavorDot,
                                        { backgroundColor: color },
                                      ]}
                                    />
                                    <Text
                                      style={[
                                        Fonts.size14,
                                        Fonts.weight700,
                                        Gutters.miniRMargin,
                                        {
                                          color: Colors.fontText.light.primary2,
                                        },
                                      ]}
                                    >
                                      {t(`flavor-wheel:${flavor.key}`)}
                                    </Text>
                                    <Image
                                      source={Images.rating_comment_add_flavor}
                                      resizeMode="contain"
                                      style={Layout.iconSize14}
                                    />
                                  </TouchableOpacity>
                                )
                              })}
                          </View>
                        </View>
                      )
                    })}
                </View>
              </View>
            )}
            {/* 無資料 */}
            {!!searchKeyword?.length &&
              searchValues?.length === 0 &&
              searchIsSuccess && (
                <View
                  style={[
                    Gutters.regularVMargin,
                    Gutters.regularVPadding,
                    Gutters.regularHPadding,
                    Layout.alignItemsCenter,
                  ]}
                >
                  <Text
                    style={[
                      Fonts.size16,
                      Fonts.weight700,
                      { color: Colors.fontText.light.primary2 },
                    ]}
                  >
                    抱歉，找不到相似的結果
                  </Text>
                  <Text
                    style={[
                      Fonts.size14,
                      Fonts.weight400,
                      { color: Colors.fontText.light.primary2 },
                    ]}
                  >
                    請嘗試重新輸入關鍵字
                  </Text>
                </View>
              )}
          </View>
        </FadeInView>
      </ScrollView>
      <DownLoadSign />
    </View>
  )
}

const getStyle = (searchKeyword: string) =>
  StyleSheet.create({
    searchInputContainer: {
      width: '90%',
      height: 40,
      backgroundColor: Colors.background.default,
      borderRadius: 8,
      borderColor: searchKeyword?.length
        ? Colors.primary
        : 'rgba(255, 255, 255, 0.6)',
      borderWidth: 1,
      alignItems: 'center',
    },
    searchInput: {
      width: '90%',
      marginLeft: 4,
      outlineStyle: 'none',
    },
    flavorSelectedItemContainer: {
      flexWrap: 'wrap',
    },
    flavorSelectedItem: {
      width: 'fit-content',
      marginLeft: 16,
      marginBottom: 16,
      paddingVertical: 8,
      paddingHorizontal: 16,
      borderRadius: 80,
      backgroundColor: Colors.darkLight,
    },
    flavorDot: {
      width: 8,
      height: 8,
      borderRadius: 4,
    },
  })

export default FlavorContainer
