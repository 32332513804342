import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import {
  AccessibilityRole,
  FlatList,
  Image,
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import { Avatar } from 'react-native-paper'
import { AvatarImageSource } from 'react-native-paper/lib/typescript/components/Avatar/AvatarImage'
import StarRating, { StarIconProps } from 'react-native-star-rating-widget'
import Svg, { Path, SvgProps } from 'react-native-svg'
import { useDispatch } from 'react-redux'
import ReactTimeAgo from 'react-time-ago'

import { useFlavorData, useShare, useTheme } from '@/Hooks'
import { getRoute, navigate } from '@/Navigators/utils'
import { noteApi } from '@/Services/modules/note'
import { globalActions } from '@/Store/Global'
import { Colors } from '@/Theme/Variables'
import {
  capitalizedSentence,
  capitalizeFirstLetter,
  getConfig,
} from '@/Util/global'
import { PagesEnum } from '@/Navigators/Application'
import { MainPagesEnum } from '@/Navigators/Main'

type Props = {
  // 資料
  id: string
  spiritId: string
  content: string
  rating: number
  createdAt: string
  updatedAt: string
  flavors: string[]
  counters: {
    likers: number
    comments: number
  }
  user: {
    id: number
    avatar: AvatarImageSource | string
    name: string
    notesCount: number
    title: {
      name: string
      imageUrl: string
    }
  }
  likeClicked: boolean
  // 看有沒有登入
  getMeData: any
  // time ago 語系（從外面拿避免過多 ReviewCard 會重複執行一樣動作）
  language: string
  // 顯示底下 CTA
  showCTA?: boolean
  // 顯示使用者頭像與名稱
  showUser?: boolean
  // 顯示所有風味
  showWholeFlavors?: boolean
  // 顯示右上角的更多按鈕
  showMore?: boolean
  // 更多按鈕的 callback
  onPressMore?: () => void
  // 只有一個 ReviewCard
  isSingle?: boolean
  // 單獨顯示此篇筆記頁面
  isMain?: boolean
  // 圖片
  imagePath?: string
  // 產品名稱
  productName?: string
  // 呈現產品名
  showProductName?: boolean
  // 按讚的 callback
  onPressLike?: () => void
}

const StarEmpty = (props: SvgProps) => (
  <Svg {...props}>
    <Path
      d="M6 0L7.34708 4.1459H11.7063L8.17963 6.7082L9.52671 10.8541L6 8.2918L2.47329 10.8541L3.82037 6.7082L0.293661 4.1459H4.65292L6 0Z"
      fill="white"
      fillOpacity="0.38"
    />
  </Svg>
)

const StarHalf = (props: SvgProps) => (
  <Svg {...props}>
    <Path
      d="M5.70634 0L7.05342 4.1459H11.4127L7.88597 6.7082L9.23305 10.8541L5.70634 8.2918L2.17963 10.8541L3.52671 6.7082L0 4.1459H4.35925L5.70634 0Z"
      fill="white"
      fillOpacity="0.38"
    />
    <Path
      d="M2.17963 10.8541L5.70634 8.2918V0L4.35925 4.1459H0L3.52671 6.7082L2.17963 10.8541Z"
      fill="#EDD0A3"
    />
  </Svg>
)

const StarFull = (props: SvgProps) => (
  <Svg {...props}>
    <Path
      d="M6 0L7.34708 4.1459H11.7063L8.17963 6.7082L9.52671 10.8541L6 8.2918L2.47329 10.8541L3.82037 6.7082L0.293661 4.1459H4.65292L6 0Z"
      fill="#EECD96"
    />
  </Svg>
)

export const StarIcon12 = ({ color, size, type }: StarIconProps) => {
  if (type === 'empty') {
    return <StarEmpty fill={color} width={size} height={size} />
  }

  if (type === 'half') {
    return <StarHalf fill={color} width={size} height={size} />
  }

  return <StarFull fill={color} width={size} height={size} />
}

const ReviewCard = ({
  id,
  spiritId,
  content,
  rating,
  createdAt,
  updatedAt,
  flavors,
  counters,
  likeClicked,
  language,
  user,
  showCTA,
  showUser,
  showWholeFlavors,
  isSingle,
  getMeData,
  isMain,
  showMore,
  onPressMore,
  imagePath,
  productName,
  showProductName,
  onPressLike,
}: Props) => {
  const { Layout, Images, Gutters, Fonts } = useTheme()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const INIT_RENDER_NUM = 5
  const route = getRoute()
  const { handleGetFlavorColor } = useFlavorData()
  const shareData = useShare({
    url: `${getConfig()?.DOMAIN}/ReviewNote?id=${id}`,
    title: '',
    text: '',
    snackbarContent: '成功複製分享連結！',
  })

  const [toggleLikeRequest] = noteApi.usePostToggleLikeMutation({})

  const [componentLiked, setComponentLiked] = React.useState(likeClicked)

  const handleClickLike = () => {
    if (!getMeData) {
      openLoginDialog()
    }
    if (onPressLike) {
      onPressLike()
      setComponentLiked(!componentLiked)
    }
    toggleLikeRequest({ id })
  }

  const openLoginDialog = () => {
    dispatch(
      globalActions.openBottomDialog({
        visible: true,
        title: t('camera.bottomDialogTitle'),
        disabledBackgroundClose: true,
        content: (
          <View
            style={[
              Layout.center,
              Gutters.regularVMargin,
              Gutters.regularHPadding,
            ]}
          >
            <Image
              source={Images.bottom_dialog_login_leave}
              resizeMode="contain"
              style={[Gutters.regularBMargin, Layout.iconSize64]}
            />
            <TouchableOpacity
              style={[
                Layout.fullWidth,
                Layout.center,
                Gutters.smallBMargin,
                styles.loginBtn,
              ]}
              onPress={() => {
                dispatch(globalActions.closeBottomDialog())
                // @ts-ignore
                navigate(PagesEnum.LoginMainContainer)
              }}
            >
              <Text
                style={[
                  Fonts.weight500,
                  Fonts.size14,
                  { color: Colors.primaryText },
                ]}
              >
                {t('camera.bottomDialogButton1')}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={[
                Layout.fullWidth,
                Layout.center,
                Gutters.smallBMargin,
                styles.loginCancelBtn,
              ]}
              onPress={() => {
                dispatch(globalActions.closeBottomDialog())
              }}
            >
              <Text style={{ color: Colors.white }}>
                {t('camera.bottomDialogButton2')}
              </Text>
            </TouchableOpacity>
          </View>
        ),
      }),
    )
  }

  // 要記錄返回上一頁要到哪一頁
  const handleNavigateToNote = () => {
    if (route?.name) {
      let page
      switch (route?.name) {
        case PagesEnum.ProductDetail:
          page = PagesEnum.ProductDetail
          break
        case PagesEnum.ReviewNoteList:
          page = PagesEnum.ReviewNoteList
          break
        case MainPagesEnum.Home:
          page = MainPagesEnum.Home
          break
      }
      // @ts-ignore
      navigate(PagesEnum.ReviewNote, {
        id,
        page,
      })
    }
  }

  const handleFlavorsList = useCallback(() => {
    if (!flavors?.length) {
      return []
    }
    if (showWholeFlavors) {
      return flavors
    }
    return flavors.slice(0, INIT_RENDER_NUM)
  }, [flavors, showWholeFlavors])

  // 如果已經是 ReviewNote 就不用導頁
  const handleRedirect = () => {
    if (route?.name === PagesEnum.ReviewNote) {
      return
    }
    handleNavigateToNote()
  }

  // 點擊產品圖放大
  const handleOpenLightBox = () => {
    dispatch(
      globalActions.openProductImageLightBox({
        visible: true,
        imagePath: imagePath,
        imageStyle: {
          width: 300,
          height: 300,
          borderRadius: 10,
        },
        containerStyle: {
          backgroundColor: 'transparent',
        },
      }),
    )
  }

  const handelRenderFlavors = ({ item }: any) => {
    const color = handleGetFlavorColor(item)
    return (
      <View style={[styles.flavorsItem]}>
        <View
          style={[
            Gutters.miniRMargin,
            styles.flavorsDot,
            { backgroundColor: color },
          ]}
        />
        <Text
          style={[
            Fonts.size12,
            Fonts.weight400,
            { color: Colors.fontText.light.primary2 },
          ]}
        >
          {capitalizeFirstLetter(t(`flavor-wheel:${item}`))}
        </Text>
      </View>
    )
  }

  const renderFlavorsFooter = () => {
    if (flavors?.length <= INIT_RENDER_NUM || showWholeFlavors) {
      return null
    }
    return (
      <TouchableOpacity onPress={handleRedirect}>
        <Text style={[Fonts.weight700, { color: Colors.white }]}>...</Text>
      </TouchableOpacity>
    )
  }

  return (
    <View
      accessibilityRole={'article' as AccessibilityRole}
      nativeID="user-review"
      style={[
        !isSingle && Gutters.regularBMargin,
        !isSingle && Gutters.smallTPadding,
        Layout.fullWidth,
      ]}
    >
      <TouchableOpacity onPress={handleRedirect}>
        {showMore && (
          <TouchableOpacity style={styles.moreBtn} onPress={onPressMore}>
            <Image
              source={Images.more}
              resizeMode="contain"
              style={Layout.iconSize24}
            />
          </TouchableOpacity>
        )}
        <TouchableOpacity
          style={[
            Layout.row,
            Layout.alignItemsCenter,
            showProductName ? Gutters.tinyBMargin : Gutters.smallBMargin,
          ]}
          onPress={() => {
            navigate('ProfileMainContainer', { id: user?.id })
          }}
        >
          {!isSingle && (
            <>
              {showUser && user?.avatar ? (
                <Avatar.Image
                  size={48}
                  source={{ uri: user?.avatar as AvatarImageSource }}
                  style={[Gutters.tinyRMargin]}
                />
              ) : (
                <Avatar.Image
                  size={48}
                  source={Images.defaultAvatar}
                  style={[Gutters.tinyRMargin]}
                />
              )}
            </>
          )}

          <View>
            {showUser && (
              <View>
                <Text
                  style={[
                    Fonts.size14,
                    Fonts.weight400,
                    { color: Colors.fontText.light.primary2, marginBottom: 4 },
                  ]}
                >
                  {`${user?.name || '此用戶不存在'}`}
                </Text>
                <View style={[Layout.row, Layout.alignItemsCenter]}>
                  {user?.title?.name && (
                    <View style={[styles.titleContainer]}>
                      <Text
                        style={[
                          Fonts.size12,
                          Fonts.weight500,
                          { color: Colors.fontText.light.primary2 },
                        ]}
                      >
                        {user?.title?.name}
                      </Text>
                    </View>
                  )}
                  {user?.notesCount && (
                    <Text
                      style={[
                        Fonts.size12,
                        Fonts.weight400,
                        { color: Colors.fontText.light.primary3 },
                      ]}
                    >
                      {`${user?.notesCount} Ratings`}
                    </Text>
                  )}
                </View>
              </View>
            )}
          </View>
        </TouchableOpacity>
        {showProductName && (
          <View style={[Gutters.tinyBMargin]}>
            <Text
              style={[
                Fonts.size14,
                Fonts.weight500,
                { color: Colors.fontText.light.primary2 },
              ]}
            >
              {capitalizedSentence(productName || '')}
            </Text>
          </View>
        )}
        <View
          style={[
            Gutters.tinyBMargin,
            Layout.fullWidth,
            Layout.alignItemsStart,
          ]}
        >
          <StarRating
            rating={rating}
            starSize={12}
            color="#EECD96"
            StarIconComponent={StarIcon12}
            onChange={e => {
              console.log(e)
            }}
            starStyle={styles.starStyle}
            animationConfig={{
              scale: 1,
            }}
          />
          <FlatList
            data={handleFlavorsList()}
            renderItem={handelRenderFlavors}
            keyExtractor={item => item}
            horizontal={!showWholeFlavors}
            style={[Layout.fullWidth, Layout.row, Layout.flexWrap]}
            showsHorizontalScrollIndicator={false}
            ListFooterComponent={renderFlavorsFooter}
            ListFooterComponentStyle={[Gutters.tinyTPadding]}
          />
        </View>
        <View style={[Gutters.smallBMargin]}>
          <Text
            accessibilityRole={'heading' as AccessibilityRole}
            // @ts-ignore
            accessibilityLevel={3}
            nativeID="user-content"
            style={{ color: Colors.white }}
            numberOfLines={isMain ? 999 : 3}
          >
            {content}
          </Text>
        </View>
        {!!imagePath?.length && (
          <View style={[Gutters.smallBMargin, { width: 60 }]}>
            <TouchableOpacity onPress={handleOpenLightBox}>
              {Platform.OS === 'web' ? (
                <img
                  src={imagePath || ''}
                  alt={`${productName}/${user?.id || ''}` || ''}
                  title={`${productName}/${user?.id || ''}` || ''}
                  style={{
                    width: 60,
                    height: 60,
                    borderRadius: 8,
                    objectFit: 'cover',
                  }}
                />
              ) : (
                <Image
                  source={{ uri: imagePath || '' }}
                  resizeMode="cover"
                  style={[Layout.iconSize60, { borderRadius: 8 }]}
                />
              )}
            </TouchableOpacity>
          </View>
        )}
        <View
          style={[
            Layout.row,
            Layout.alignItemsCenter,
            Layout.justifyContentBetween,
          ]}
        >
          <Text style={[Fonts.size12, { color: Colors.gray }]}>
            <ReactTimeAgo
              date={Date.parse(createdAt)}
              locale={language || 'en'}
            />
          </Text>
          {
            // 如果 showCTA 為 true，則顯示 CTA
            showCTA && (
              <View style={[Layout.row, Layout.alignItemsCenter]}>
                <View style={[Layout.row, Gutters.smallRMargin]}>
                  <TouchableOpacity onPress={handleClickLike}>
                    <Image
                      source={
                        componentLiked
                          ? Images.product_detail_thumb_up_fill
                          : Images.product_detail_thumb_up
                      }
                      resizeMode="contain"
                      style={[Layout.iconSize16, Gutters.tinyRMargin]}
                    />
                  </TouchableOpacity>
                  <Text style={{ color: Colors.fontText.light.primary2 }}>
                    {counters.likers || 0}
                  </Text>
                </View>
                <View style={[Layout.row, Gutters.smallRMargin]}>
                  <TouchableOpacity onPress={handleRedirect}>
                    <Image
                      source={Images.product_detail_comment}
                      resizeMode="contain"
                      style={[Layout.iconSize16, Gutters.tinyRMargin]}
                    />
                  </TouchableOpacity>
                  <Text style={{ color: Colors.fontText.light.primary2 }}>
                    {counters.comments || 0}
                  </Text>
                </View>
                <TouchableOpacity style={[Layout.row]} onPress={shareData}>
                  <Image
                    source={Images.product_detail_share}
                    resizeMode="contain"
                    style={{
                      width: 16,
                      height: 16,
                      marginRight: 8,
                    }}
                  />
                  <Text style={{ color: Colors.fontText.light.primary2 }}>
                    {t('components.reviewCard.share')}
                  </Text>
                </TouchableOpacity>
              </View>
            )
          }
        </View>
      </TouchableOpacity>
    </View>
  )
}

const styles = StyleSheet.create({
  flavorsItem: {
    backgroundColor: Colors.background.onSurface,
    paddingVertical: 4,
    paddingHorizontal: 8,
    textAlign: 'center',
    borderRadius: 32,
    marginRight: 10,
    marginBottom: 8,
    width: 'fit-content',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  flavorsDot: {
    width: 8,
    height: 8,
    borderRadius: 4,
  },
  moreBtn: {
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 1,
  },
  loginBtn: {
    height: 48,
    backgroundColor: Colors.primary,
    paddingVertical: 9,
    borderRadius: 8,
  },
  loginCancelBtn: {
    paddingVertical: 9,
    borderRadius: 8,
  },
  starStyle: {
    marginLeft: 0,
    marginRight: 2,
    marginBottom: 5,
  },
  titleContainer: {
    backgroundColor: '#6147E3',
    alignSelf: 'flex-start',
    borderTopRightRadius: 16,
    borderBottomRightRadius: 16,
    borderBottomLeftRadius: 16,
    marginRight: 4,
    paddingVertical: 4,
    paddingHorizontal: 8,
  },
})

ReviewCard.defaultProps = {
  showCTA: false,
  showUser: true,
  showWholeFlavors: false,
  showMore: false,
  isSingle: false,
  isMain: false,
  onPressMore: () => {},
  imagePath: '',
  productName: '',
  showProductName: false,
  onPressLike: () => {},
}

export default ReviewCard
