import React, { useCallback } from 'react'
import {
  View,
  TouchableOpacity,
  Image,
  Text,
  FlatList,
  StyleSheet,
  Dimensions,
  TextInput,
  ImageBackground,
  ScrollView,
} from 'react-native'
import { useCategory, useTheme } from '@/Hooks'
import { Colors } from '@/Theme/Variables'
import { FadeInView, Header } from '@/Components'
import { navigate } from '@/Navigators/utils'
import { DownLoadSign } from '@/Components'
import { PagesEnum } from '@/Navigators/Application'
import { MainPagesEnum } from '@/Navigators/Main'

const SpiritsCategoriesContainer = ({ _route, navigation }: any) => {
  const { Layout, Images, Fonts, Gutters } = useTheme()
  const styles = getStyle()
  const categoryData = useCategory()

  const handleGoBack = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack()
      return
    }

    navigate(PagesEnum.Main, {
      screen: MainPagesEnum.Home,
    })
  }, [navigation])

  const handleClickCategoryCard = useCallback(
    ({ keys, filters }: { keys: string; filters: string }) => {
      navigation.navigate(PagesEnum.SearchListContainer, {
        filters,
        keys,
      })
    },
    [navigation],
  )

  const renderHeader = () => {
    return (
      <View
        style={[
          Layout.fullWidth,
          Gutters.regularHPadding,
          Gutters.regularBPadding,
        ]}
      >
        <FadeInView duration={350} style={Layout.fullWidth}>
          <TouchableOpacity
            style={[Layout.row, styles.searchInputContainer]}
            onPress={() => navigation.navigate(PagesEnum.SearchListContainer)}
          >
            <Image
              style={Layout.iconSize24}
              source={Images.home_search}
              resizeMode="contain"
            />
            <TextInput
              style={[styles.searchInput, { color: Colors.white }]}
              placeholder="搜尋今天喝的烈酒，例如 Jimbeam"
              placeholderTextColor={Colors.gray}
            />
          </TouchableOpacity>
        </FadeInView>
      </View>
    )
  }

  const CategoryItem = useCallback(
    (item: any) => {
      let icon
      if (item?.icon) {
        icon = item.icon
      }
      const { title, zhTitle, keys, block, filters } = item
      return (
        <TouchableOpacity
          style={[Gutters.smallBMargin, styles.categoryItemContainer]}
          onPress={() => handleClickCategoryCard({ filters, keys })}
        >
          <ImageBackground
            style={[Layout.fullWidth, Layout.alignItemsCenter, Layout.row]}
            source={block}
            resizeMode="cover"
          >
            <View style={styles.categoryBorder} />
            <View style={[Layout.fullWidth, styles.padding32]}>
              <Text
                style={[
                  Fonts.weight500,
                  Fonts.size26,
                  Gutters.miniBMargin,
                  styles.categoryTitle,
                  { color: Colors.white, lineHeight: 26 },
                ]}
              >
                {title}
              </Text>
              <Text
                style={[Fonts.weight400, Fonts.size14, { color: Colors.white }]}
              >
                {zhTitle}
              </Text>
            </View>
            <Image
              source={icon}
              style={styles.categoryIcon}
              resizeMode="contain"
            />
          </ImageBackground>
        </TouchableOpacity>
      )
    },
    [Fonts, Gutters, Layout, handleClickCategoryCard, styles],
  )

  return (
    <View
      style={[
        Layout.fill,
        {
          backgroundColor: Colors.background.default,
          height: Dimensions.get('window').height,
        },
      ]}
    >
      <Header
        title={'探索各類酒'}
        leftIcon={
          <Image
            style={[styles.arrowLeftIcon]}
            source={Images.arrowLeft}
            resizeMode="contain"
          />
        }
        leftIconPress={handleGoBack}
        rightEmptyIconWidth="50"
      />
      <ScrollView>
        <FadeInView duration={500} style={Layout.fill}>
          {renderHeader()}
          <View style={(Layout.fill, Gutters.regularHPadding)}>
            <FlatList
              data={categoryData}
              renderItem={({ item }) => <CategoryItem {...item} />}
              keyExtractor={item => item.id}
            />
          </View>
        </FadeInView>
      </ScrollView>
      <DownLoadSign />
    </View>
  )
}

const getStyle = () =>
  StyleSheet.create({
    arrowLeftIcon: {
      width: 24,
      height: 24,
      paddingLeft: 50,
    },
    searchInputContainer: {
      width: '100%',
      height: 40,
      backgroundColor: Colors.background.default,
      borderRadius: 8,
      alignItems: 'center',
      paddingLeft: 12,
      borderWidth: 1,
      borderColor: Colors.gray,
    },
    searchInput: {
      width: '100%',
      marginLeft: 4,
      // @ts-ignore
      outlineStyle: 'none',
    },
    categoryItemContainer: {
      height: 114,
      borderRadius: 8,
      overflow: 'hidden',
    },
    categoryBorder: {
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
      borderRadius: 8,
      borderWidth: 2,
      borderColor: 'rgba(255, 255, 255, 0.6)',
    },
    categoryIcon: {
      width: 100,
      height: 100,
      position: 'absolute',
      right: 0,
      bottom: 0,
    },
    categoryTitle: {
      lineHeight: 24,
      letterSpacing: 2,
      fontFamily: 'Poppins',
    },
    padding32: {
      padding: 32,
    },
  })

export default SpiritsCategoriesContainer
