export default {
  startup: {
    title: '您的全方位烈酒品飲助理',
  },
  mainTab: {
    home: 'Home',
    profile: 'Profile',
  },
  camera: {
    back: 'Back',
    startIdentify: 'Identify',
    scanLabelTitle1: '對準酒標',
    bottomDialogTitle: '請登入或註冊以繼續使用服務',
    bottomDialogButton1: '登入或註冊',
    bottomDialogButton2: '返回',
  },
  commentModal: {
    headerTitle: '編輯文字筆記',
    headerTemplateTitle: '範本',
    headerTemplateTitle_edit: '編輯範本',
    flavorPlaceholder: '這支酒的香氣、口感、餘韻是什麼風味？最後來個總結吧！',
    templatePlaceholder: '輸入你的範本內容',
  },
  mySpirit: {
    title: '我的品飲筆記',
    add: 'Add Spirits',
    price: '${{price}}',
  },
  wishlist: {
    title: '願望清單',
    price: '${{price}}',
  },
  productDetails: {
    rated: 'Your rating',
    averagePrice: '參考價格',
    price: 'NTD ${{price}}',
    review: '我的品飲筆記',
    addReview: 'Add Your Review',
    description: '關於這支酒',
    detail: '酒品詳情',
    anchorTitle1: '我的品飲筆記',
    anchorTitle2: '關於這支酒',
    anchorTitle3: '風味與筆記',
    chipTitle1: '最新',
    hintTitle: '提示',
    hintContent1: '您可以從這裡開始撰寫您的品飲筆記',
    hintContent2: '紀錄收藏好酒，與其他好友分享心得',
  },
  profile: {
    welcome: 'Welcome {{name}}',
    inviteFriends: '邀請好友',
    mySpirit: 'My Spirits',
    wishlist: 'Wishlist',
    history: '辨識紀錄',
  },
  profileDetail: {
    setting: '設定',
    policy: '政策條款',
    releaseNote: '版本',
    logout: '登出',
  },
  ratingAndComment: {
    headerRight: '完成',
    addComment: '填寫你的品飲筆記',
    editComment: '編輯你的品飲筆記',
  },
  scanLoading: {
    done: '完成！',
    scanAgain: 'No result found, please scan again',
  },
  scanResult: {
    title: 'Results',
    bestMatch: 'Best Match',
    otherMatch: 'Related Searches',
  },
  reviewNoteList: {
    title: '全部品飲筆記',
    section1: '我的品飲筆記',
    section2: '其他品飲筆記',
  },
  reviewNote: {
    title: '筆記詳情',
    inputPlaceholder: '你有什麼想法呢？',
    comments: '留言',
  },
  components: {
    reviewCard: {
      share: '分享',
    },
    spiritCardWithContent: {
      editSpirit: '修改品飲筆記',
      removeSpirit: '刪除品飲筆記',
      removeSpiritDialogTitle: '刪除品飲筆記',
      removeSpiritDialogContent: '此行為無法復原，是否確認刪除品飲筆記？',
      removeSpiritDialogConfirm: '刪除品飲筆記',
      removeSpiritDialogCancel: '取消',
      shareSpirit: '分享品飲筆記',
      noRating: '暫無評分',
      noContent: '尚未撰寫品飲筆記',
      editNote: '修改品飲筆記',
      addNote: '新增品飲筆記',
      addWishlist: '願望清單',
    },
  },
  // 風味
  flavor: {
    winey: 'Winey',
    sherried: 'Sherried',
    nutty: 'Nutty',
    chocolate: 'Chocolate',
    oily: 'Oily',
    cereal: 'Cereal',
    'cooked-mash': 'Cooked-mash',
    'cooked-veg': 'Cooked-veg',
    'malt-extract': 'Malt-extract',
    husky: 'Husky',
    yeasty: 'Yeasty',
    fruity: 'Fruity',
    citric: 'Citric',
    'fresh-fruit': 'Fresh-fruit',
    'cooked-fruit': 'Cooked-fruit',
    'dried-fruit': 'Dried-fruit',
    solvent: 'Solvent',
    floral: 'Floral',
    fragrant: 'Fragrant',
    'green-house': 'Green-house',
    leafy: 'Leafy',
    'hay-like': 'Hay-like',
    peaty: 'Peaty',
    medicinal: 'Medicinal',
    smokey: 'Smokey',
    kippery: 'Kippery',
    mossy: 'Mossy',
    feinty: 'Feinty',
    honey: 'Honey',
    leathery: 'Leathery',
    tobacco: 'Tobacco',
    sweaty: 'Sweaty',
    plastic: 'Plastic',
    sulphury: 'Sulphury',
    vegitative: 'Vegitative',
    'coal-gas': 'Coal-gas',
    rubbery: 'Rubbery',
    sandy: 'Sandy',
    woody: 'Woody',
    toasted: 'Toasted',
    vanilla: 'Vanilla',
    'old-wood': 'Old-wood',
    'new-wood': 'New-wood',
  },
  welcome: 'Welcome to React Native WenLiBaiMobile by TheCodingMachine',
  actions: {
    continue: 'Continue',
  },
  example: {
    helloUser: 'I am a fake user, my name is {{name}}',
    labels: {
      userId: 'Enter a user id',
    },
  },
}
