import { useTheme, useWindowSize } from '@/Hooks'
import { Colors, Height } from '@/Theme/Variables'
import React from 'react'
import { View, Image } from 'react-native'

interface Props {
  // 如果是在有 tab 的頁面需要 padding bottom 撐起來
  bottomSpace?: boolean
}

export const SHOW_BOTTOM_WARNING_SIGN_WIDTH = 430

const WarningSignComponent = ({ bottomSpace = false }: Props) => {
  const { Layout, Images } = useTheme()
  const windowSize = useWindowSize()

  const tabPagePaddingBottom = bottomSpace ? 8 : 0

  // 如果是桌機版大網，就不用出現警告圖示，因為會統一出現在桌機版的最下方
  if (windowSize.width > SHOW_BOTTOM_WARNING_SIGN_WIDTH) {
    return <></>
  }

  return (
    <View
      style={{
        paddingBottom: tabPagePaddingBottom,
        backgroundColor: Colors.background.onSurface,
      }}
    >
      <Image
        source={Images.warningSign}
        resizeMode="cover"
        style={[
          Layout.fullWidth,
          {
            height: Height.height48,
          },
        ]}
      />
    </View>
  )
}

WarningSignComponent.defaultProps = {
  bottomSpace: false,
}

export default WarningSignComponent
