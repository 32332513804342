import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  View,
  TouchableOpacity,
  Image,
  Text,
  StyleSheet,
  ImageBackground,
  ScrollView,
  FlatList,
  Dimensions,
  AccessibilityRole,
  ActivityIndicator,
  Platform,
} from 'react-native'
import { useTheme, useFullScreen } from '@/Hooks'
import { Appbar } from 'react-native-paper'
import Jssha from 'jssha'
import { BorderRadius, Colors, Height } from '@/Theme/Variables'
import {
  FadeInView,
  ImageLightBox,
  ReviewCard,
  WarningSignComponent,
  ChipItem,
} from '@/Components'
import {
  capitalizedSentence,
  getConfig,
  handleFormatRating,
} from '@/Util/global'
import StarRating from 'react-native-star-rating-widget'
import { useDispatch, useSelector } from 'react-redux'
import { globalActions } from '@/Store/Global'
import { spiritsApi } from '@/Services/modules/spirits'
import { userApi } from '@/Services/modules/users'
import { GetMySingleSpiritResponse } from '@/Services/modules/spirits/spirits.type'
import { useTranslation } from 'react-i18next'
import { useInView } from 'react-intersection-observer'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { Helmet } from 'react-helmet-async'
import { spiritActions } from '@/Store/Spirit'
import { useIsFocused } from '@react-navigation/native'
import { LinearGradient } from 'expo-linear-gradient'
import { RootState } from '@/Store'
import { navigateAndSimpleReset } from '@/Navigators/utils'
import { StarIcon14 } from '@/Components/SpiritCard'
import noteApi from '@/Services/modules/note'
import { DropdownsType, NestedSetType } from '@/Services/modules/note/note.type'
import { DownLoadSign } from '@/Components'
import { AuthPagesEnum } from '@/Navigators/withAuthPages'
import { PagesEnum } from '@/Navigators/Application'
import { MainPagesEnum } from '@/Navigators/Main'

const ProductDetailContainer = ({ route, navigation }: any) => {
  const { Layout, Gutters, Images, Fonts } = useTheme()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const isFocused = useIsFocused()
  const isFullScreen = useFullScreen()
  const { page, searchKeyword, titleUrlSlug } = route.params

  // 錨點列表
  const anchorList = useMemo(() => {
    return [
      {
        id: '1',
        title: t('productDetails.anchorTitle1'),
      },
      {
        id: '2',
        title: t('productDetails.anchorTitle2'),
      },
      {
        id: '3',
        title: t('productDetails.anchorTitle3'),
      },
    ]
  }, [t])

  // 筆記列表分類
  const chipList = useMemo(() => {
    return [t('productDetails.chipTitle1'), '最有幫助']
  }, [t])

  // 筆記分類項目名稱
  enum CHIP_NAME {
    LATEST = '最新',
    MOST_HELPFUL = '最有幫助',
  }

  const language = useSelector((state: RootState) => state.theme.language)
  // workaround for first time rating will not update the rate immediately
  const addRatingLoading = useSelector(
    (state: RootState) => state.spirit.addRatingLoading,
  )

  const [showHeader, setShowHeader] = useState(false)
  const [productData, setProductData] = useState<GetMySingleSpiritResponse>()
  const [selectedChip, setSelectedChip] = useState<string>(chipList[0])
  const [activeAnchor, setActiveAnchor] = useState<string>(anchorList[0].id)
  const [showHint, setShowHint] = useState<boolean>(true)
  const [isCollected, setIsCollected] = useState<boolean>(false)
  const [expendDesc, setExpendDesc] = useState<boolean>(false)
  const [showExpendFlavor, setShowExpendFlavor] = useState<boolean>(false)
  const [showExpendDesc, setShowExpendDesc] = useState<boolean>(false)
  const [stopScrollState, setStopScrollState] = useState<boolean>(false)
  const [othersReview, setOtherReview] = useState<any>()
  const [openProductImageLightBox, setOpenProductImageLightBox] =
    useState<boolean>(false)

  // 登入資訊
  const { data: getMeData, isError: getMeDataIsError } = userApi.useGetMeQuery()

  // 酒的資料
  const {
    data: boozeData,
    isLoading: singleBoozesLoading,
    isSuccess: singeBoozesSuccess,
    isError: singleBoozesError,
  } = spiritsApi.useGetMySingleSpiritQuery(route.params?.id, {
    skip: !route.params?.id,
  })

  // 酒的評論種類
  const { data: featuredNotes, isSuccess: featuredNotesIsSuccess } =
    spiritsApi.useGetSpiritFeaturedNotesQuery(route.params?.id, {
      skip: !route.params?.id,
    })

  // 加入 my spirits(用來取消狀態)
  const [_addMySpiritsRequest, { reset: addMySpiritReset }] =
    spiritsApi.usePostMyBoozeReviewMutation({
      fixedCacheKey: 'shared-update-post',
    })

  // 取得該支酒的個人資訊，包括是否有收藏、個人評論
  const { data: personalSpiritData, isLoading: personalSpiritLoading } =
    spiritsApi.useGetSpiritPersonalInfoQuery(
      {
        id: route.params?.id,
      },
      {
        skip: !getMeData,
      },
    )

  // 加入 wishlist
  const [
    addWishlistRequest,
    { isSuccess: addWishlistSuccess, reset: addWishlistReset },
  ] = spiritsApi.usePostAddWishlistMutation({})

  // 移除 wishlist
  const [
    removeWishlistRequest,
    { isSuccess: removeWishlistSuccess, reset: removeWishlistReset },
  ] = spiritsApi.usePostRemoveWishlistMutation({})

  // 取得下拉選單（容器）
  noteApi.useGetNoteDropdownsOptionsQuery({
    type: DropdownsType.GLASS,
    locale: language,
  })

  // 取得下拉選單（配餐）
  noteApi.useGetNoteDropdownsOptionsQuery({
    type: DropdownsType.PAIRING,
    locale: language,
  })

  // 取得風味資訊
  noteApi.useGetNestedSetQuery({
    type: NestedSetType.FLAVOR_WHEEL,
  })

  const hasMyReview = useMemo(() => {
    const mySpiritNotesData = personalSpiritData?.data?.myNotes[0]
    return (
      !!mySpiritNotesData?.content ||
      !!mySpiritNotesData?.flavors?.length ||
      !!mySpiritNotesData?.rating
    )
  }, [personalSpiritData?.data?.myNotes])

  // 觀察 錨點 section 是否進入畫面
  // 第一個區塊（關於這支酒）
  const { ref: firstSectionRef } = useInView({
    /* Optional options */
    threshold: 0,
    onChange: inView => {
      if (inView) {
        setActiveAnchor(anchorList[0].id)
      }
    },
  })
  const firstRef = useRef()
  const setFirstRefs = useCallback(
    (node: any) => {
      firstRef.current = node
      firstSectionRef(node)
    },
    [firstSectionRef],
  )
  // 第二個區塊 （詳情）
  const { ref: secondSectionRef } = useInView({
    /* Optional options */
    threshold: 0,
    rootMargin: '500px 0px -500px 0px',
    onChange: inView => {
      if (inView) {
        setActiveAnchor(anchorList[1].id)
      }
    },
  })
  const secondRef = useRef()
  const setSecondRefs = useCallback(
    (node: any) => {
      secondRef.current = node
      secondSectionRef(node)
    },
    [secondSectionRef],
  )
  // 第三個區塊 （評論）
  const { ref: thirdSectionRef } = useInView({
    /* Optional options */
    threshold: 0,
    rootMargin: '400px 0px -400px 0px',
    onChange: inView => {
      if (inView) {
        setActiveAnchor(anchorList[2].id)
      }
    },
  })
  const thirdRef = useRef()
  const setThirdRefs = useCallback(
    (node: any) => {
      thirdRef.current = node
      thirdSectionRef(node)
    },
    [thirdSectionRef],
  )

  const anchorRefList = useMemo(() => {
    return [firstRef, secondRef, thirdRef]
  }, [firstRef, secondRef, thirdRef])

  const anchors = anchorList.map((item, index) => {
    return {
      ...item,
      ref: anchorRefList[index],
    }
  })

  const styles = getStyle(showHeader)

  useEffect(() => {
    navigation.setOptions({
      headerShown: false,
      title: boozeData?.title
        ? `${boozeData?.title} | 烈酒探索家 - 您的全方位烈酒品飲助理`
        : '',
    })
  }, [navigation, boozeData])

  // 如果 URl 不完整及跳轉到 404
  useEffect(() => {
    if (singleBoozesError) {
      navigation.navigate(PagesEnum.NotFound)
    }
  }, [singleBoozesError, navigation])

  // 檢查需不需要出現 hint
  useEffect(() => {
    const getHint = async () => {
      const hint = await AsyncStorage.getItem('showHint')
      if (hint) {
        setShowHint(false)
      }
    }
    getHint()
  }, [])

  // 成功取得單一酒品資料
  useEffect(() => {
    if (singeBoozesSuccess) {
      setProductData(boozeData)

      // 如果超過三則風味統計顯示查看更多按鈕
      if (boozeData?.flavors?.breakdown?.length > 3) {
        setShowExpendFlavor(true)
      }
    }
  }, [boozeData, singeBoozesSuccess])

  // 成功取得單一酒品評論種類
  useEffect(() => {
    if (featuredNotesIsSuccess) {
      setOtherReview(featuredNotes?.data?.latest)
    }
  }, [featuredNotesIsSuccess, featuredNotes])

  // 紀錄是否收藏
  useEffect(() => {
    setIsCollected(personalSpiritData?.data?.collections?.collected)
  }, [personalSpiritData?.data])

  // 新增或移除 wishlist
  useEffect(() => {
    if (addWishlistSuccess) {
      setIsCollected(true)
      addWishlistReset()
    }
    if (removeWishlistSuccess) {
      setIsCollected(false)
      removeWishlistReset()
    }
  }, [
    addWishlistSuccess,
    removeWishlistSuccess,
    addWishlistReset,
    removeWishlistReset,
    dispatch,
  ])

  // 如果 show hint 停止 scroll
  useEffect(() => {
    if (showHint && !hasMyReview) {
      setStopScrollState(true)
    } else {
      setStopScrollState(false)
    }
  }, [showHint, hasMyReview])

  // 清除掃描加入 my spirit狀態
  useEffect(() => {
    addMySpiritReset()
    addWishlistReset()
    removeWishlistReset()
  }, [addMySpiritReset, addWishlistReset, removeWishlistReset])

  // 清除紀錄評論資料
  useEffect(() => {
    if (isFocused) {
      dispatch(spiritActions.clearSpiritData())
      setExpendDesc(false)
    }
  }, [isFocused, dispatch])

  const handleGoBack = useCallback(() => {
    if (navigation.canGoBack()) {
      const fromScanResult = navigation
        .getState()
        ?.routes?.some(
          (routeItem: any) =>
            routeItem.name === AuthPagesEnum.ScanResultContainer,
        )

      if (fromScanResult) {
        navigation.navigate(PagesEnum.Main, {
          screen: MainPagesEnum.Camera,
        })
        return
      } else {
        navigation.goBack()
        return
      }
    }

    navigateAndSimpleReset(PagesEnum.Main)
  }, [navigation])

  const handleOneLineEllipsis = useCallback(
    (value: string) => {
      const numberOfText = language === 'en' ? 25 : 15
      if (value?.length < numberOfText) {
        return value
      }
      return value.substring(0, numberOfText) + '...'
    },
    [language],
  )

  const handleDetectYOffset = (event: any) => {
    const { y } = event.nativeEvent.contentOffset
    if (y > 180 && !showHeader) {
      setShowHeader(true)
    }
    if (y < 180 && showHeader) {
      setShowHeader(false)
    }
  }

  const handleClickClip = (title: string) => {
    setSelectedChip(title)
    if (title === CHIP_NAME.LATEST && featuredNotes) {
      setOtherReview(featuredNotes?.data?.latest)
    }
    if (title === CHIP_NAME.MOST_HELPFUL && featuredNotes) {
      setOtherReview(featuredNotes?.data?.mostHelpful)
    }
  }

  // 取得 product id 的 hash number 值
  const handleGetIdHashNum = useCallback((id: number) => {
    if (id) {
      const shaObj = new Jssha('SHA-256', 'TEXT')
      shaObj.update(id.toString())
      const hash = shaObj.getHash('HEX')
      const number = parseInt(hash, 16)
      return String(number)[0]
    }
  }, [])

  // 選出特定的背景圖（依據 category & region）
  const handleGetSpecificBackground = useCallback(
    (data: GetMySingleSpiritResponse) => {
      const { category, region } = data
      // 將背景圖對應的 category & region 放入 key 圖片則放入 value，將來記得也要添加英文版
      const bgs = [
        { key: '威士忌低地區', value: Images.product_detail_top_lowland },
        { key: '威士忌斯佩賽區', value: Images.product_detail_top_speyside },
        { key: '白蘭地干邑', value: Images.product_detail_top_cognacRegion },
        {
          key: '威士忌坎貝爾鎮區',
          value: Images.product_detail_top_whiskyCampbeltown,
        },
        {
          key: '威士忌高地區',
          value: Images.product_detail_top_whiskyHighlands,
        },
        { key: '威士忌艾雷島區', value: Images.product_detail_top_whiskyIslay },
      ]
      const bgMap = new Map()
      bgs.forEach(({ key, value }) => bgMap.set(key, value))

      if (bgMap.has(`${category}${region}`)) {
        return bgMap.get(`${category}${region}`)
      }
    },
    [Images],
  )

  // 渲染特定或是隨機的背景圖
  const renderBackground = useCallback(
    (data: GetMySingleSpiritResponse) => {
      // 如果有指定特定的背景圖
      if (handleGetSpecificBackground(data)) {
        return handleGetSpecificBackground(data)
      }

      // 隨機產生背景圖
      const bgs = [
        Images.product_detail_random1,
        Images.product_detail_random2,
        Images.product_detail_random3,
        Images.product_detail_random4,
        Images.product_detail_random5,
        Images.product_detail_random6,
      ]

      const idHashValue = handleGetIdHashNum(data.id)
      const imageIndex = Number(idHashValue)
      return imageIndex < bgs?.length
        ? bgs[imageIndex]
        : bgs[imageIndex % bgs?.length]
    },
    [Images, handleGetIdHashNum, handleGetSpecificBackground],
  )

  const openLoginDialog = () => {
    dispatch(
      globalActions.openBottomDialog({
        visible: true,
        title: t('camera.bottomDialogTitle'),
        handleClickBackground: () => setStopScrollState(false),
        disabledBackgroundClose: true,
        content: (
          <View
            style={[
              Layout.center,
              Gutters.regularVMargin,
              Gutters.regularHPadding,
            ]}
          >
            <Image
              source={Images.bottom_dialog_login_leave}
              resizeMode="contain"
              style={[Gutters.regularBMargin, Layout.iconSize64]}
            />
            <TouchableOpacity
              style={[
                Layout.fullWidth,
                Layout.center,
                Gutters.smallBMargin,
                Gutters.tinyVPadding,
                {
                  backgroundColor: Colors.primary,
                  borderRadius: BorderRadius.radius8,
                  height: Height.height48,
                },
              ]}
              onPress={() => {
                dispatch(globalActions.closeBottomDialog())
                setStopScrollState(false)
                navigation.navigate(PagesEnum.LoginMainContainer)
              }}
            >
              <Text
                style={[
                  Fonts.weight500,
                  Fonts.size14,
                  { color: Colors.primaryText },
                ]}
              >
                {t('camera.bottomDialogButton1')}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={[
                Layout.fullWidth,
                Layout.center,
                Gutters.smallBMargin,
                Gutters.tinyVPadding,
                {
                  height: Height.height48,
                },
              ]}
              onPress={() => {
                dispatch(globalActions.closeBottomDialog())
                setStopScrollState(false)
              }}
            >
              <Text style={{ color: Colors.white }}>
                {t('camera.bottomDialogButton2')}
              </Text>
            </TouchableOpacity>
          </View>
        ),
      }),
    )
    setStopScrollState(true)
  }

  const handleAddRating = () => {
    if (getMeDataIsError) {
      openLoginDialog()
    } else {
      const routeParams: any = {
        id: route.params?.id,
        titleUrlSlug: titleUrlSlug,
        mySpiritId: personalSpiritData?.data?.myNotes[0]?.id,
        page: page !== undefined ? page : '',
        searchKeyword: searchKeyword !== undefined ? searchKeyword : '',
      }
      Object.keys(routeParams).forEach(key => {
        if (routeParams[key] === undefined || routeParams[key] === '') {
          delete routeParams[key]
        }
      })
      // 如果有評論過直接進入 ReviewDetail
      if (hasMyReview) {
        navigation.navigate(AuthPagesEnum.ReviewDetail, routeParams)
      } else {
        navigation.navigate(
          AuthPagesEnum.RatingAndCommentContainer,
          routeParams,
        )
      }
    }
  }

  const handleClickAnchor = (id: string) => {
    const target: any = anchors.find(item => item.id === id)
    if (target?.ref?.current) {
      target.ref.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
      setActiveAnchor(id)
    }
  }

  const handleAddWishlist = (spiritId: string | number) => {
    // 沒有登入的話，跳出登入視窗
    if (getMeDataIsError) {
      openLoginDialog()
      return
    }

    if (isCollected) {
      removeWishlistRequest({
        id: 'default',
        spiritId: spiritId.toString(),
      })
    } else {
      addWishlistRequest({
        id: 'default',
        spiritId: spiritId.toString(),
      })
    }
    setIsCollected(!isCollected)
  }

  const handleShare = () => {
    const og_url = titleUrlSlug
      ? `${getConfig()?.DOMAIN}/${language}/${titleUrlSlug}/${boozeData?.id}`
      : `${getConfig()?.DOMAIN}/${language}/${boozeData?.id}`

    // @ts-ignore
    if (navigator.share) {
      // @ts-ignore
      navigator
        .share({
          title: `${
            boozeData?.title || ''
          } | 烈酒探索家 - 您的全方位烈酒品飲助理`,
          text: '我在全方位烈酒品飲助理烈酒探索家上看到這款酒，覺得這支酒如何？一起來評價吧！',
          url: og_url,
        })
        .then(() => console.log('navigator share success！'))
        .catch((error: any) => console.log('navigator share error:', error))
      return
    }
    // @ts-ignore
    navigator.clipboard.writeText(og_url).then(
      () => {
        dispatch(
          globalActions.openSnackbar({
            visible: true,
            message: '成功複製連結！',
            type: 'default',
            showIcon: false,
          }),
        )
      },
      () => {
        console.error('Failed to copy to clipboard')
      },
    )
  }

  // 偵測描述是否超過三行
  const onTextLayout = useCallback((e: any) => {
    const TWO_LINES_HEIGHT = 42
    setShowExpendDesc(e.nativeEvent.layout.height > TWO_LINES_HEIGHT)
  }, [])

  const renderTitle = useCallback(() => {
    return (
      <View>
        <Text
          style={[
            Fonts.size16,
            Fonts.weight500,
            Fonts.textCenter,
            { color: Colors.secondaryText },
          ]}
        >
          {handleOneLineEllipsis(capitalizedSentence(boozeData?.brand)) || ''}
        </Text>
        <Text
          style={[
            Fonts.size12,
            Fonts.weight400,
            Fonts.textCenter,
            { color: Colors.gray },
          ]}
        >
          {handleOneLineEllipsis(capitalizedSentence(boozeData?.title)) || ''}
        </Text>
      </View>
    )
  }, [Fonts, boozeData, handleOneLineEllipsis])

  // Header
  const renderHeader = () => {
    const showHeaderSize = 24
    return (
      <FadeInView duration={350}>
        <Appbar.Header
          style={[
            Layout.fullWidth,
            Layout.row,
            Layout.justifyContentBetween,
            Gutters.regularHPadding,
            {
              backgroundColor: showHeader
                ? Colors.background.default
                : Colors.transparent,
            },
          ]}
        >
          <View
            style={[
              Layout.colCenter,
              Layout.iconSize32,
              Gutters.regularRPadding,
              {
                borderRadius: BorderRadius.radius16,
                backgroundColor: Colors.fontText.dark.primary3,
              },
            ]}
          >
            <TouchableOpacity
              onPress={handleGoBack}
              style={{
                paddingLeft: navigation.canGoBack() ? 32 : 24,
                paddingBottom: 2,
              }}
            >
              <Image
                style={[Layout.iconSize24]}
                source={
                  navigation.canGoBack() ? Images.arrowLeft : Images.close
                }
                resizeMode="contain"
              />
            </TouchableOpacity>
          </View>
          <View>{showHeader && renderTitle()}</View>
          <View
            style={[
              Layout.colCenter,
              Layout.iconSize32,
              Gutters.regularRPadding,
              {
                paddingRight: 24,
                borderRadius: BorderRadius.radius16,
                backgroundColor: Colors.fontText.dark.primary3,
              },
            ]}
          >
            <TouchableOpacity
              onPress={handleShare}
              style={{ paddingLeft: 24, paddingBottom: 2 }}
            >
              <Image
                style={[
                  {
                    width: showHeaderSize,
                    height: showHeaderSize,
                  },
                ]}
                source={Images.share}
                resizeMode="contain"
              />
            </TouchableOpacity>
          </View>
        </Appbar.Header>
      </FadeInView>
    )
  }

  // SEO Metadata
  const renderSEOMetadata = () => {
    const og_url = titleUrlSlug
      ? `${getConfig()?.DOMAIN}/${language}/${titleUrlSlug}/${boozeData?.id}`
      : `${getConfig()?.DOMAIN}/${language}/${boozeData?.id}`

    return (
      <Helmet>
        <meta
          name="title"
          content={`${
            capitalizedSentence(boozeData?.title) || ''
          } | 烈酒探索家 - 您的全方位烈酒品飲助理`}
        />
        <meta name="description" content={boozeData?.description || ''} />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content={`${
            capitalizedSentence(boozeData?.title) || ''
          } | 烈酒探索家 - 您的全方位烈酒品飲助理`}
        />
        <meta
          property="og:description"
          content={boozeData?.description || ''}
        />
        <meta property="og:url" content={og_url} />
        <meta property="og:locale" content={getMeData?.locale || 'zh'} />
        <meta property="og:image" content={boozeData?.imageUrl || ''} />
      </Helmet>
    )
  }

  // JSON-LD
  const renderJSON_LD = () => {
    if (singeBoozesSuccess) {
      const data: any = {
        '@context': 'https://schema.org',
        '@type': 'Product',
        aggregateRating: {
          '@type': 'AggregateRating',
          ratingValue: boozeData?.ratings?.average || 0,
          reviewCount: boozeData?.ratings?.count || 0,
        },
        description: boozeData?.description || '',
        name: capitalizedSentence(boozeData?.title) || '',
        image: boozeData?.imageUrl || '',
        offers: {
          '@type': 'Offer',
          availability: 'https://schema.org/InStock',
          price: boozeData?.marketPrice || 0,
          priceCurrency: 'TWD',
        },
        review: othersReview
          ? othersReview?.map((note: any) => ({
              '@type': 'Review',
              datePublished: note?.createdAt || '',
              reviewBody: note?.content || '',
              author: {
                '@type': 'Person',
                name: note?.user?.name || '',
              },
              reviewRating: {
                '@type': 'Rating',
                bestRating: '5',
                ratingValue: note?.rating || 0,
                worstRating: '1',
              },
            }))
          : [],
        brand: {
          '@type': 'Brand',
          name: boozeData?.brand || '',
        },
      }
      // 如果沒有評分，先將 aggregateRating 移除，不然 search console 會噴錯
      if (
        boozeData?.ratings?.average === 0 ||
        boozeData?.ratings?.count === 0
      ) {
        delete data.aggregateRating
      }
      return <script type="application/ld+json">{JSON.stringify(data)}</script>
    }
  }

  // 顯示提示點擊 CTA button
  const renderHintContainer = () => {
    if (showHint && !hasMyReview) {
      return (
        <TouchableOpacity
          style={styles.hint}
          onPress={async () => {
            setShowHint(false)
            setStopScrollState(false)
            await AsyncStorage.setItem('showHint', 'showed')
          }}
        >
          <Text style={styles.hintTitle}>{t('productDetails.hintTitle')}</Text>
          <Text style={styles.hintText}>
            {t('productDetails.hintContent1')}
          </Text>
          <Text style={styles.hintText}>
            {t('productDetails.hintContent2')}
          </Text>
          <Image
            source={Images.product_detail_hint}
            resizeMode="contain"
            style={styles.hitIcon}
          />
        </TouchableOpacity>
      )
    }
  }

  // 使用者自己的評論
  const renderUserReviewItem = ({ item }: any) => {
    const {
      id,
      content,
      rating,
      createdAt,
      updatedAt,
      user,
      flavors,
      counters,
      likeClicked,
      image,
    } = item
    return (
      <ReviewCard
        id={id}
        spiritId={route?.params?.id}
        productName={boozeData?.title}
        content={content}
        likeClicked={likeClicked}
        rating={rating}
        createdAt={createdAt}
        flavors={flavors}
        user={user}
        counters={counters}
        showCTA
        showUser={false}
        language={language}
        isSingle
        showWholeFlavors
        getMeData={getMeData}
        imagePath={image?.url}
        updatedAt={updatedAt}
      />
    )
  }

  // 其他人評論評論
  const renderReviewItem = ({ item }: any) => {
    const {
      id,
      content,
      rating,
      createdAt,
      updatedAt,
      user,
      flavors,
      counters,
      likeClicked,
      image,
    } = item
    return (
      <ReviewCard
        id={id}
        spiritId={route?.params?.id}
        productName={boozeData?.title}
        content={content}
        rating={rating}
        likeClicked={likeClicked}
        createdAt={createdAt}
        updatedAt={updatedAt}
        counters={counters}
        flavors={flavors}
        user={user}
        showCTA
        language={language}
        getMeData={getMeData}
        imagePath={image?.url}
      />
    )
  }

  // 所有評論區塊
  const renderAllReviewSection = () => {
    if (othersReview?.length) {
      return (
        <View>
          <View style={[Layout.row, Gutters.smallBMargin]}>
            {chipList.map(item => (
              <ChipItem
                key={item}
                title={item}
                selected={item === selectedChip}
                handleClickClip={handleClickClip}
              />
            ))}
          </View>
          <View>
            <FlatList
              keyExtractor={(_item, index) => index.toString()}
              data={othersReview || []}
              renderItem={renderReviewItem}
              ItemSeparatorComponent={() => (
                <View
                  style={[
                    Layout.fullWidth,
                    { height: 1, backgroundColor: Colors.background.onSurface },
                  ]}
                />
              )}
            />
          </View>
          <View style={[Layout.rowCenter, Gutters.largeBMargin]}>
            <TouchableOpacity
              onPress={() => {
                if (!getMeData) {
                  openLoginDialog()
                  return
                }
                navigation.navigate(PagesEnum.ReviewNoteList, {
                  id: productData?.id,
                  titleUrlSlug: titleUrlSlug,
                })
              }}
            >
              <Text
                style={[Fonts.bold, Fonts.size14, { color: Colors.primary }]}
              >
                查看更多筆記
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      )
    }
    return (
      <View style={[Gutters.largeBPadding, Gutters.largeTPadding]}>
        <Text
          style={[
            Fonts.weight400,
            Fonts.size14,
            Fonts.textCenter,
            { color: Colors.secondaryText },
          ]}
        >
          風味如何？搶先發表筆記分享品飲心得
        </Text>
      </View>
    )
  }

  // 大家嚐到的風味區塊
  const renderFlavorSection = () => {
    // 風味圖 map
    const renderFlavorImage = {
      cereal: Images.product_detail_flavor_icon_cereal,
      feinty: Images.product_detail_flavor_icon_feinty,
      floral: Images.product_detail_flavor_icon_floral,
      fruity: Images.product_detail_flavor_icon_fruity,
      peaty: Images.product_detail_flavor_icon_peaty,
      sulphury: Images.product_detail_flavor_icon_sulphury,
      'winey-extractive': Images.product_detail_flavor_icon_winey,
      woody: Images.product_detail_flavor_icon_woody,
      others: Images.product_detail_flavor_icon_other,
    }

    // 資料處理（先顯示三筆，如果點開查看更多則顯示全部）
    const flavorsData = showExpendFlavor
      ? boozeData?.flavors?.breakdown?.slice(0, 3)
      : boozeData?.flavors?.breakdown

    // 子風味層三筆資料
    const flavorChildren = (flavor: any) => {
      let result = ''
      const firstThree = flavor?.children?.slice(0, 3)

      firstThree.forEach((item: any, index: number) => {
        // 只有一筆
        if (firstThree?.length === 1) {
          result += `${t(`flavor-wheel:${item?.slug}`)}`
          return
        }
        if (index === 2) {
          result += `${t(`flavor-wheel:${item?.slug}`)}...`
          return
        }
        if (index === 1 && firstThree?.length === 2) {
          result += `${t(`flavor-wheel:${item?.slug}`)}`
          return
        } else {
          result += `${t(`flavor-wheel:${item?.slug}`)},`
          return
        }
      })

      return result
    }

    return (
      <View style={[Gutters.regularBPadding]}>
        {!!flavorsData?.length &&
          flavorsData.map((item: any) => {
            const includesFlavorString = flavorChildren(item)

            return (
              <View
                style={[
                  Gutters.regularBMargin,
                  Layout.row,
                  Layout.alignItemsCenter,
                ]}
              >
                <Image
                  // @ts-ignore
                  source={renderFlavorImage[item.slug] as any}
                  resizeMode="cover"
                  style={[Layout.iconSize40, Gutters.tinyRMargin]}
                />
                <View>
                  <Text
                    style={[
                      Fonts.size14,
                      Fonts.weight400,
                      { color: Colors.fontText.light.primary2 },
                    ]}
                  >{`${t(`flavor-wheel:${item.slug}`)} 風味被標記了 ${
                    item.count
                  } 次`}</Text>
                  <Text
                    style={[
                      Fonts.size12,
                      Fonts.weight400,
                      { color: Colors.fontText.light.primary3 },
                    ]}
                  >{`包含：${includesFlavorString}`}</Text>
                </View>
              </View>
            )
          })}
        {showExpendFlavor && (
          <TouchableOpacity
            style={[Layout.rowCenter]}
            onPress={() => setShowExpendFlavor(false)}
          >
            <Text
              style={[Fonts.size16, Fonts.weight500, { color: Colors.primary }]}
            >
              查看更多
            </Text>
          </TouchableOpacity>
        )}
      </View>
    )
  }

  if (singleBoozesLoading) {
    return (
      <View style={[Layout.fill]}>
        <View
          style={[
            Layout.fill,
            Layout.colCenter,
            { backgroundColor: Colors.background.default },
          ]}
        >
          <ActivityIndicator size="large" color={Colors.primary} />
        </View>
      </View>
    )
  }

  return (
    <View
      style={[
        Layout.fill,
        {
          backgroundColor: Colors.background.default,
          height: Dimensions.get('window').height,
        },
      ]}
    >
      {renderSEOMetadata()}
      {singeBoozesSuccess && renderJSON_LD()}
      {showHeader && renderHeader()}
      <ScrollView
        style={[Layout.fill, Gutters.largeBPadding]}
        scrollEnabled={!stopScrollState}
        onScroll={handleDetectYOffset}
        scrollEventThrottle={16}
        contentContainerStyle={[
          {
            paddingBottom: 100,
          },
        ]}
        stickyHeaderIndices={[3]} // 這邊記得要對到錨點
        showsVerticalScrollIndicator={false}
      >
        {/* 提示 hint */}
        {renderHintContainer()}
        <ImageBackground
          style={[Layout.fullWidth, { height: isFullScreen ? 224 : 200 }]}
          source={renderBackground(boozeData || {})}
          resizeMode="cover"
          blurRadius={2}
        >
          {/* 黑底遮罩(全部) */}
          <LinearGradient
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
            }}
            colors={['rgba(0,0,0,0.4)', 'rgba(0,0,0,0.4)']}
            end={{ y: 1, x: 0 }}
          />
          {/* 黑底遮罩(底部) */}
          <LinearGradient
            style={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              width: '100%',
              height: 50,
            }}
            colors={['transparent', 'black']}
            end={{ y: 1, x: 0 }}
          />
          {!showHeader && renderHeader()}
          <FadeInView duration={350} style={Gutters.regularRPadding}>
            <View
              style={[
                Gutters.regularBMargin,
                Gutters.regularHPadding,
                Layout.row,
                Layout.alignItemsCenter,
              ]}
            >
              <View style={{ width: '40%' }}>
                <TouchableOpacity
                  onPress={() => setOpenProductImageLightBox(true)}
                >
                  {Platform.OS === 'web' ? (
                    <img
                      src={boozeData?.imageUrl || ''}
                      alt={boozeData?.title || ''}
                      title={boozeData?.title || ''}
                      style={styles.mainImage}
                    />
                  ) : (
                    <Image
                      style={styles.mainImage}
                      source={{ uri: boozeData?.imageUrl }}
                      resizeMode="cover"
                    />
                  )}
                </TouchableOpacity>
                {/* 左方小圖 */}
                {/* {hasScanImage && (
                  <Image
                    style={[styles.secondImage]}
                    source={{ uri: mySpiritsData?.data[0]?.scannedImageUrl }}
                    resizeMode="cover"
                  />
                )} */}
              </View>
              <View
                accessibilityRole={'main' as AccessibilityRole}
                style={[Layout.fullWidth, Gutters.regularRPadding]}
              >
                {/* 酒的名稱與酒廠資料 */}
                <View style={[Gutters.regularVPadding, { width: '75%' }]}>
                  {/* 品牌酒廠資訊 */}
                  <Text
                    accessibilityRole={'heading' as AccessibilityRole}
                    // @ts-ignore
                    accessibilityLevel={2}
                    nativeID="brand"
                    numberOfLines={3}
                    style={[
                      Fonts.size12,
                      Fonts.weight400,
                      { color: Colors.secondaryText },
                    ]}
                  >
                    {capitalizedSentence(productData?.brand as string) || ''}
                  </Text>
                  {/* 酒品名稱資訊 */}
                  <Text
                    accessibilityRole={'heading' as AccessibilityRole}
                    accessibilityLabel="1"
                    nativeID="title"
                    numberOfLines={3}
                    style={[
                      Fonts.size14,
                      Fonts.weight700,
                      { color: Colors.white },
                    ]}
                  >
                    {capitalizedSentence(productData?.title || '')}
                  </Text>
                  <View
                    accessibilityRole={'main' as AccessibilityRole}
                    style={[Layout.row]}
                  >
                    {productData?.type && (
                      <Text
                        accessibilityRole={'heading' as AccessibilityRole}
                        // @ts-ignore
                        accessibilityLevel={2}
                        nativeID="type"
                        numberOfLines={3}
                        style={[
                          Fonts.size14,
                          Gutters.tinyRMargin,
                          { color: Colors.gray },
                        ]}
                      >
                        {productData?.country
                          ? `${productData?.type}/`
                          : productData?.type}
                      </Text>
                    )}
                    {productData?.country && (
                      <Text
                        accessibilityRole={'heading' as AccessibilityRole}
                        // @ts-ignore
                        accessibilityLevel={2}
                        nativeID="country"
                        numberOfLines={3}
                        style={[
                          Fonts.size12,
                          Gutters.tinyRMargin,
                          { color: 'rgba(255, 255, 255, 0.6)' },
                        ]}
                      >
                        {productData?.country || ''}
                      </Text>
                    )}
                  </View>
                </View>
              </View>
            </View>
          </FadeInView>
        </ImageBackground>
        <FadeInView duration={550} style={Gutters.smallHPadding}>
          {/* 評分 */}
          <View
            accessibilityRole={'article' as AccessibilityRole}
            style={[
              styles.ratingContainer,
              Layout.row,
              Layout.alignItemsCenter,
              Layout.justifyContentBetween,
              Gutters.smallHPadding,
              Gutters.smallVPadding,
            ]}
          >
            {addRatingLoading ? (
              <View
                style={[
                  Layout.rowCenter,
                  Gutters.largeLPadding,
                  { width: '50%' },
                ]}
              >
                <ActivityIndicator size="large" color={Colors.primary} />
              </View>
            ) : (
              <View style={Layout.row}>
                {productData?.ratings?.average ? (
                  <Text
                    accessibilityRole={'heading' as AccessibilityRole}
                    // @ts-ignore
                    accessibilityLevel={2}
                    nativeID="rating"
                    style={[
                      Fonts.weight400,
                      Fonts.size32,
                      Gutters.smallRMargin,
                      { color: Colors.secondaryText },
                    ]}
                  >
                    {handleFormatRating(
                      productData?.ratings?.average as number,
                    )}
                  </Text>
                ) : (
                  <Text
                    accessibilityRole={'heading' as AccessibilityRole}
                    // @ts-ignore
                    accessibilityLevel={2}
                    nativeID="rating"
                    style={[
                      Fonts.weight400,
                      Fonts.size20,
                      Gutters.smallRMargin,
                      { color: Colors.secondaryText },
                    ]}
                  >
                    暫無評分
                  </Text>
                )}
                <View>
                  <StarRating
                    rating={productData?.ratings?.average || 0}
                    starSize={18}
                    color="#EECD96"
                    onChange={e => {
                      console.log(e)
                    }}
                    StarIconComponent={StarIcon14}
                    starStyle={{
                      marginLeft: 0,
                      marginRight: 0,
                    }}
                    animationConfig={{
                      scale: 1,
                    }}
                  />
                  <Text
                    accessibilityRole={'heading' as AccessibilityRole}
                    // @ts-ignore
                    accessibilityLevel={2}
                    nativeID="total-rating"
                    style={{ color: Colors.gray }}
                  >
                    {`( ${productData?.ratings?.count} 個評分 )`}
                  </Text>
                </View>
              </View>
            )}
            <View accessibilityRole={'article' as AccessibilityRole}>
              <View
                style={[Layout.row, Layout.alignItemsCenter, Layout.fullHeight]}
              >
                <View
                  style={{
                    width: 1,
                    height: 50,
                    backgroundColor: Colors.darkText,
                    marginRight: 16,
                  }}
                />
                <View>
                  {productData?.marketPrice ? (
                    <Text
                      accessibilityRole={'heading' as AccessibilityRole}
                      // @ts-ignore
                      accessibilityLevel={2}
                      nativeID="price"
                      style={[
                        Fonts.size16,
                        Fonts.weight400,
                        {
                          color: Colors.secondaryText,
                        },
                      ]}
                    >
                      {t('productDetails.price', {
                        price: productData?.marketPrice?.toLocaleString(),
                      })}
                    </Text>
                  ) : (
                    <Text
                      style={[
                        Fonts.size16,
                        Gutters.regularLPadding,
                        {
                          color: Colors.fontText.light.primary4,
                          textAlign: 'right',
                        },
                      ]}
                    >
                      暫無
                    </Text>
                  )}
                  <Text
                    style={[
                      Fonts.size12,
                      Fonts.weight400,
                      {
                        color: Colors.gray,
                        textAlign: 'right',
                      },
                    ]}
                  >
                    {t('productDetails.averagePrice')}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </FadeInView>
        {/* 錨點 header  */}
        <FadeInView duration={350}>
          <ScrollView
            style={[{ backgroundColor: Colors.background.default }]}
            contentContainerStyle={[Layout.fullWidth]}
            horizontal
          >
            {anchorList.map(item => {
              // 如果沒有我的筆記，則不顯示該 anchor
              if (item.id === '1' && !hasMyReview) {
                return
              }
              const textStyle = {
                color: Colors.secondaryText,
                fontSize: 14,
                opacity: activeAnchor === item.id ? 1 : 0.6,
              }
              const anchorStyle =
                activeAnchor === item.id
                  ? styles.activeAnchorItem
                  : styles.anchorItem
              return (
                <TouchableOpacity
                  key={item.id}
                  style={anchorStyle}
                  onPress={() => handleClickAnchor(item.id)}
                >
                  <Text style={[Fonts.textCenter, { ...textStyle }]}>
                    {item.title}
                  </Text>
                </TouchableOpacity>
              )
            })}
          </ScrollView>
        </FadeInView>
        <FadeInView duration={550}>
          {/* 使用者自己的評論 */}
          {hasMyReview && (
            <View
              style={[
                Gutters.regularVPadding,
                Gutters.regularHPadding,
                Gutters.tinyBMargin,
                Layout.fullWidth,
                { backgroundColor: Colors.background.surface },
              ]}
            >
              <View ref={setFirstRefs} style={styles.anchorView} />
              <View style={[Layout.fullWidth]}>
                <View style={[Layout.row, Layout.justifyContentBetween]}>
                  <Text
                    style={[
                      Gutters.tinyBMargin,
                      Fonts.weight700,
                      Fonts.size16,
                      {
                        color: Colors.secondaryText,
                      },
                    ]}
                  >
                    {t('productDetails.review')}
                  </Text>
                </View>
                <View style={[Layout.fullWidth, Layout.row]}>
                  {addRatingLoading || personalSpiritLoading ? (
                    <View style={[Layout.fullWidth, Layout.rowCenter]}>
                      <ActivityIndicator size="large" color={Colors.primary} />
                    </View>
                  ) : (
                    <FlatList
                      keyExtractor={(_item, index) => index.toString()}
                      data={[personalSpiritData?.data?.myNotes[0]]}
                      renderItem={renderUserReviewItem}
                    />
                  )}
                </View>
              </View>
            </View>
          )}
          {/* 描述 */}
          <View
            accessibilityRole={'main' as AccessibilityRole}
            style={[
              Gutters.regularVPadding,
              Gutters.regularHPadding,
              Gutters.tinyBMargin,
              { backgroundColor: Colors.background.surface },
            ]}
          >
            <View ref={setSecondRefs} style={styles.anchorView} />
            <View accessibilityRole={'article' as AccessibilityRole}>
              <Text
                style={[
                  Fonts.size16,
                  Fonts.weight700,
                  Gutters.tinyBMargin,
                  {
                    color: Colors.secondaryText,
                  },
                ]}
              >
                {t('productDetails.description')}
              </Text>
              <Text
                accessibilityRole={'heading' as AccessibilityRole}
                // @ts-ignore
                accessibilityLevel={2}
                nativeID="description"
                numberOfLines={expendDesc ? 999 : 3}
                onLayout={onTextLayout}
                style={[
                  Fonts.weight400,
                  {
                    color: Colors.secondaryText,
                    lineHeight: 21,
                  },
                ]}
              >
                {productData?.description}
              </Text>
            </View>
            {showExpendDesc && (
              <TouchableOpacity
                onPress={() => {
                  setExpendDesc(!expendDesc)
                  handleClickAnchor('2')
                }}
              >
                <Text
                  style={[
                    Fonts.size16,
                    Fonts.weight500,
                    Fonts.textCenter,
                    Gutters.regularTMargin,
                    {
                      color: Colors.primary,
                    },
                  ]}
                >
                  {expendDesc ? '收合內容' : '查看更多'}
                </Text>
              </TouchableOpacity>
            )}
          </View>
          {/* 酒精濃度、容量等等資訊 */}
          <View
            accessibilityRole={'main' as AccessibilityRole}
            style={[
              Gutters.tinyBMargin,
              Gutters.regularHPadding,
              Gutters.regularTPadding,
              { backgroundColor: Colors.background.surface },
            ]}
          >
            <View style={styles.anchorView} />
            <View
              accessibilityRole={'article' as AccessibilityRole}
              style={[Gutters.regularBPadding]}
            >
              <Text
                style={[
                  Fonts.size16,
                  Fonts.weight700,
                  Gutters.tinyBMargin,
                  {
                    color: Colors.secondaryText,
                  },
                ]}
              >
                {t('productDetails.detail')}
              </Text>
              {/* 類別 */}
              {productData?.category && (
                <View
                  style={[
                    Layout.row,
                    Layout.justifyContentBetween,
                    Gutters.tinyBMargin,
                  ]}
                >
                  <Text style={[Fonts.bold, { color: Colors.secondaryText }]}>
                    類別
                  </Text>
                  <Text
                    accessibilityRole={'heading' as AccessibilityRole}
                    // @ts-ignore
                    accessibilityLevel={3}
                    nativeID="category"
                    style={{ color: Colors.gray }}
                  >
                    {productData?.category}
                  </Text>
                </View>
              )}
              {/* 酒廠 */}
              {productData?.brand && (
                <View
                  style={[
                    Layout.row,
                    Layout.justifyContentBetween,
                    Gutters.tinyBMargin,
                  ]}
                >
                  <Text style={[Fonts.bold, { color: Colors.secondaryText }]}>
                    酒廠
                  </Text>
                  <Text
                    accessibilityRole={'heading' as AccessibilityRole}
                    // @ts-ignore
                    accessibilityLevel={3}
                    nativeID="brand"
                    style={{ color: Colors.gray }}
                  >
                    {capitalizedSentence(productData?.brand)}
                  </Text>
                </View>
              )}
              {/* 國家 */}
              {productData?.country && (
                <View
                  style={[
                    Layout.row,
                    Layout.justifyContentBetween,
                    Gutters.tinyBMargin,
                  ]}
                >
                  <Text style={[Fonts.bold, { color: Colors.secondaryText }]}>
                    國家
                  </Text>
                  <Text
                    accessibilityRole={'heading' as AccessibilityRole}
                    // @ts-ignore
                    accessibilityLevel={3}
                    nativeID="country"
                    style={{ color: Colors.gray }}
                  >
                    {productData?.country}
                  </Text>
                </View>
              )}
              {/* 地區 */}
              {productData?.region && (
                <View
                  style={[
                    Layout.row,
                    Layout.justifyContentBetween,
                    Gutters.tinyBMargin,
                  ]}
                >
                  <Text style={[Fonts.bold, { color: Colors.secondaryText }]}>
                    地區
                  </Text>
                  <Text
                    accessibilityRole={'heading' as AccessibilityRole}
                    // @ts-ignore
                    accessibilityLevel={3}
                    nativeID="region"
                    style={{ color: Colors.gray }}
                  >
                    {productData?.region}
                  </Text>
                </View>
              )}
              {/* 裝瓶廠 */}
              {productData?.facts?.bottler && (
                <View
                  style={[
                    Layout.row,
                    Layout.justifyContentBetween,
                    Gutters.tinyBMargin,
                  ]}
                >
                  <Text style={[Fonts.bold, { color: Colors.secondaryText }]}>
                    裝瓶廠
                  </Text>
                  <Text
                    accessibilityRole={'heading' as AccessibilityRole}
                    // @ts-ignore
                    accessibilityLevel={3}
                    nativeID="region"
                    style={{ color: Colors.gray }}
                  >
                    {productData?.facts?.bottler}
                  </Text>
                </View>
              )}
              {/* 酒精濃度 */}
              {productData?.strength && (
                <View
                  style={[
                    Layout.row,
                    Layout.justifyContentBetween,
                    Gutters.tinyBMargin,
                  ]}
                >
                  <Text style={[Fonts.bold, { color: Colors.secondaryText }]}>
                    酒精濃度
                  </Text>
                  <Text
                    accessibilityRole={'heading' as AccessibilityRole}
                    // @ts-ignore
                    accessibilityLevel={3}
                    nativeID="alcohol-by-volume"
                    style={{ color: Colors.gray }}
                  >{`${productData?.strength}%`}</Text>
                </View>
              )}
              {/* 容量 */}
              {productData?.volume && (
                <View
                  style={[
                    Layout.row,
                    Layout.justifyContentBetween,
                    Gutters.tinyBMargin,
                  ]}
                >
                  <Text style={[Fonts.bold, { color: Colors.secondaryText }]}>
                    容量
                  </Text>
                  <Text
                    accessibilityRole={'heading' as AccessibilityRole}
                    // @ts-ignore
                    accessibilityLevel={3}
                    nativeID="volume"
                    style={{ color: Colors.gray }}
                  >{`${productData?.volume}ml`}</Text>
                </View>
              )}
            </View>
          </View>
          {/* 大家嚐到的風味統計 */}
          {!!boozeData?.flavors?.breakdown.length && (
            <View
              accessibilityRole={'main' as AccessibilityRole}
              style={[
                Gutters.regularHPadding,
                Gutters.regularTPadding,
                Gutters.tinyBMargin,
                { backgroundColor: Colors.background.surface },
              ]}
            >
              <View ref={setThirdRefs} style={styles.anchorView} />
              <Text
                style={[
                  Fonts.bold,
                  Fonts.size16,
                  Gutters.smallBMargin,
                  { color: Colors.secondaryText },
                ]}
              >
                大家嚐到的風味
              </Text>
              {renderFlavorSection()}
            </View>
          )}
          {/* 所有筆記 */}
          <View
            accessibilityRole={'main' as AccessibilityRole}
            style={[
              Gutters.regularHPadding,
              Gutters.regularTPadding,
              { backgroundColor: Colors.background.surface },
            ]}
          >
            {/* 如果有風味統計就不顯示這個錨點 */}
            {!boozeData?.flavors?.breakdown.length && (
              <View ref={setThirdRefs} style={styles.anchorView} />
            )}
            <Text
              style={[
                Fonts.bold,
                Fonts.size16,
                Gutters.smallBMargin,
                { color: Colors.secondaryText },
              ]}
            >
              所有筆記
            </Text>
            {renderAllReviewSection()}
          </View>
        </FadeInView>
      </ScrollView>
      {/* CTA button */}
      <FadeInView duration={350} style={styles.bottomCTAContainer}>
        <WarningSignComponent />
        <View
          style={[
            Layout.row,
            Layout.alignItemsCenter,
            Layout.justifyContentBetween,
            Gutters.regularHPadding,
            styles.bottomCTA,
          ]}
        >
          <TouchableOpacity
            onPress={() =>
              handleAddWishlist(productData?.id as string | number)
            }
          >
            <Image
              source={isCollected ? Images.collected : Images.collect}
              resizeMode="contain"
              style={{
                width: 20,
                height: 24,
              }}
            />
          </TouchableOpacity>
          {addRatingLoading || personalSpiritLoading ? (
            <View
              style={[
                Layout.rowCenter,
                Layout.alignItemsCenter,
                styles.addReviewButton,
              ]}
            >
              <ActivityIndicator size="small" color={Colors.white} />
            </View>
          ) : (
            <TouchableOpacity
              style={[
                Layout.rowCenter,
                Layout.alignItemsCenter,
                styles.addReviewButton,
              ]}
              onPress={handleAddRating}
            >
              <Image
                source={
                  hasMyReview
                    ? Images.product_detail_edit_note
                    : Images.product_detail_add_note
                }
                resizeMode="contain"
                style={[
                  {
                    width: 24,
                    height: 24,
                    marginRight: 4,
                  },
                ]}
              />
              <Text
                style={[
                  Fonts.size16,
                  Fonts.weight500,
                  {
                    color: Colors.primaryText,
                  },
                ]}
              >
                {hasMyReview ? '修改品飲筆記' : '新增品飲筆記'}
              </Text>
            </TouchableOpacity>
          )}
        </View>
      </FadeInView>
      {/* 點擊圖片放大 */}
      <ImageLightBox
        visible={openProductImageLightBox}
        imagePath={boozeData?.imageUrl}
        imageStyle={{
          width: 300,
          height: 300,
          borderRadius: 10,
        }}
        containerStyle={{
          backgroundColor: 'transparent',
        }}
        hideModal={() => {
          setOpenProductImageLightBox(false)
        }}
      />
      <DownLoadSign bottom={140} />
    </View>
  )
}

const getStyle = (showHeader: boolean) =>
  StyleSheet.create({
    mainImage: {
      width: 100,
      height: 100,
      borderRadius: 8,
      objectFit: 'cover',
    },
    secondImage: {
      width: 54,
      height: 54,
      borderRadius: 27,
      borderColor: Colors.white,
      borderWidth: 2,
      position: 'absolute',
      top: 120,
      left: 25,
    },
    ratingContainer: {
      borderColor: Colors.darkText,
      borderWidth: 1,
      borderRadius: 8,
      backgroundColor: '#202024',
      marginBottom: 16,
    },
    activeAnchorItem: {
      flex: 1,
      borderBottomColor: Colors.primary,
      borderBottomWidth: 4,
      paddingHorizontal: 16,
      paddingVertical: 8,
      margin: 0,
    },
    anchorItem: {
      flex: 1,
      paddingHorizontal: 16,
      paddingVertical: 8,
      margin: 0,
    },
    addReviewButton: {
      backgroundColor: Colors.primary,
      borderRadius: 8,
      padding: 10,
      width: '85%',
    },
    divider: {
      width: '100%',
      height: 8,
      backgroundColor: Colors.background.surface,
    },
    bottomCTAContainer: {
      width: '100%',
      position: 'absolute',
      bottom: 0,
    },
    bottomCTA: {
      paddingVertical: 24,
      backgroundColor: Colors.background.default,
    },
    anchorView: {
      position: 'absolute',
      top: -35,
    },
    hint: {
      backgroundColor: Colors.black,
      opacity: 0.8,
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: 999,
      width: '100%',
      height: Dimensions.get('window').height,
      justifyContent: 'center',
      alignItems: 'center',
    },
    hintTitle: {
      color: Colors.white,
      fontSize: 20,
      fontWeight: '700',
      textAlign: 'center',
    },
    hintText: {
      color: Colors.white,
      fontSize: 16,
      fontWeight: '400',
    },
    hitIcon: {
      position: 'absolute',
      bottom: showHeader ? 160 : 100,
      width: 90,
      height: 120,
    },
  })

export default ProductDetailContainer
