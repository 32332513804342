import { FlavorData } from '@/Containers/data/FlavorData'
import noteApi from '@/Services/modules/note'
import { NestedSetType } from '@/Services/modules/note/note.type'

const useFlavorData = () => {
  // 取得風味資訊
  const { data, isSuccess } = noteApi.useGetNestedSetQuery({
    type: NestedSetType.FLAVOR_WHEEL,
  })

  const result: any = []
  // 放顏色
  const flavorColorMap = new Map()

  if (isSuccess) {
    FlavorData?.forEach(flavor => {
      for (const flavorRes of data?.data?.children) {
        if (flavorRes?.parentSlug === flavor?.name) {
          const flavorList: {
            i18n: string
            key: string
            name: string
            parentSlug: string
          }[] = []
          Object.keys(flavorRes?.data)?.map(key => {
            flavorList.push({
              i18n: `flavor-wheel:${key}`,
              key: key,
              name: flavorRes?.data[key],
              parentSlug: flavorRes?.parentSlug,
            })
            flavorColorMap.set(key, flavor?.color)
          })
          result.push({
            ...flavor,
            flavor: flavorList,
          })
        }
      }
    })
  }

  // 取得風味顏色
  const handleGetFlavorColor = (flavor: string) => {
    return flavorColorMap.get(flavor)
  }

  return { result, handleGetFlavorColor, isSuccess }
}

export default useFlavorData
